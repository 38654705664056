import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  totalCount: 0,
  totalPages: 1,
  entities: null,
  completedCampaigns: null,
  completedCampaignTotalCount: 0,
  completedCampaignTotalPages: 1,
  campaignDetails: {},
  applicationDetails: [],
  applications: [],
  applicationContents: null,
  contentCount: 0,
  templates: null,
  category: [],
  templateListLoading: false,
  totalCategoryCount: 0,
  filterLevels: [],
  groupedOptions: [],
  filterData: [],
  filterListLoading: false,
  templateDetails: {},
  templateData: {},
};

export const callTypes = {
  list: "list",
  action: "action",
  templateList: "templateList",
  filterList: "filterList",
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.templateList) {
        state.templateListLoading = false;
      } else if (action.payload.callType === callTypes.filterList) {
        state.filterListLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.templateList) {
        state.templateListLoading = true;
      } else if (action.payload.callType === callTypes.filterList) {
        state.filterListLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    callSuccess: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.templateList) {
        state.templateListLoading = false;
      } else if (action.payload.callType === callTypes.filterList) {
        state.filterListLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    campaignsFetched: (state, action) => {
      let { campaigns, totalCount, totalPage } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = campaigns;
      state.totalCount = totalCount;
      state.totalPages = totalPage;
    },

    campaignDetailsFetched: (state, action) => {
      let { campaignDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignDetails = campaignDetails;
    },

    applicationDetailsFetched: (state, action) => {
      let { applicationDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.applicationDetails = applicationDetails;
    },

    applicationsFetched: (state, action) => {
      let { applications, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.applications = applications;
      state.applnTotalCount = totalCount;
    },

    applicationContentFetched: (state, action) => {
      let { content, contentCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.applicationContents = content;
      state.contentCount = contentCount;
    },

    campaignDetailsFetchedToViewInApplicationList: (state, action) => {
      let { campaignDetailsForApplication } = action.payload;
      // state.listLoading = false;
      // state.error = null;
      state.campaignDetails = campaignDetailsForApplication;
    },

    completedCampaignsFetched: (state, action) => {
      let { campaigns, totalCount, totalPage } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.completedCampaigns = campaigns;
      state.completedCampaignTotalCount = totalCount;
      state.completedCampaignTotalPages = totalPage;
    },

    filterLevelsFetched: (state, action) => {
      let { filterLevels } = action.payload;
      state.filterLevels = filterLevels;
    },

    filterAndSubFilterFetched: (state, action) => {
      let { filterData } = action.payload;
      state.filterData = filterData;
      state.filterListLoading = false;
    },

    subFilterLevelsFetched: (state, action) => {
      let { groupedOptionsArray } = action.payload;
      state.groupedOptions = groupedOptionsArray;
    },

    templatesFetched: (state, action) => {
      let { templates, totalCount, totalPage } = action.payload;
      state.templateListLoading = false;
      state.error = null;
      state.templates = templates;
      state.totalCount = totalCount;
      state.totalPages = totalPage;
      state.actionsLoading = false;
    },

    templateDetailsFetched: (state, action) => {
      let { templateDetails, actionsLoading } = action.payload;
      state.actionsLoading = actionsLoading;
      state.templateDetails = templateDetails;
      state.templateData = templateDetails.templateData;
    },

    categoryFetched: (state, action) => {
      let { category, totalCategoryCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.category = category;
      state.totalCategoryCount = totalCategoryCount;
    },
    deleteTemplate: (state, action) => {
      let { templateId } = action.payload;
      state.templates = state.templates.filter(
        (item) => item.templateId !== templateId
      );
    },
  },
});
