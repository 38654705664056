import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as actions from "../../gallery/_redux/galleryActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import ComponentLoader from "../../../components/ComponentLoader";
import { Modal, Pagination } from "react-bootstrap";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useFileDownloader from "../../campaignApplications/pages/application-content-downloader/useFileDownloader";

export function Gallery() {
  const dispatch = useDispatch();
  const history = useHistory;
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFiletype] = useState();
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    loggedInUserId,
    medias,
    totalPages,
    listLoading,
    actionsLoading,
  } = useSelector(
    (state) => ({
      loggedInUserId: state.auth.user?.userId,
      medias: state.gallery.medias,
      totalPages: state.gallery.totalPages,
      // totalCount: state.gallery.totalCount,
      actionsLoading: state.gallery.actionsLoading,
      listLoading: state.gallery.listLoading,
    }),
    shallowEqual
  );

  const DateFormatter = (cellContent) => {
    let formattedDate = "";
    if (cellContent) {
      let date = parseISO(cellContent);
      formattedDate = format(date, "dd MMM yyyy").toString();
    }
    return <span>{formattedDate}</span>;
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(
      actions.getGalleryContents({
        page: currentPage,
        pageSize: 4,
        fields: [
          "applicationContentId",
          "campaignType",
          "postType",
          "creator",
          "contentMediaUrl",
          "contentInstagramUrl",
          "contentYoutubeUrl",
          "contentInstagramLikesCount",
          "contentInstagramViewsCount",
          "contentYoutubeLikesCount",
          "contentYoutubeViewsCount",
          "contentPlatform",
          "contentStatus",
          "updatedAt",
          "brand",
          "campaign",
          "postImageStyle",
          "postVideoStyle",
        ],
        loggedInUserId,
        populate: ["creator", "brand", "campaign"],
        query: {
          brandId: loggedInUserId,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserId, currentPage]);
  return (
    <>
      {listLoading ? (
        <ComponentLoader />
      ) : medias?.length ? (
        medias?.map((item, index) => (
          // <div class="col-12 pr-3 pl-0">
          // <div class="col-md-7">  <h6 class="mx-auto font-weight-bold px-5 w-100 mb-0 mt-6 text-dark-50">No campaign created yet</h6>
          // <p class="text-dark-50 mb-0 mt-5">Online Advertising flat illustration of the concept of a woman being promoted using a microphone</p>
          // <div class="w-100 mt-5"><a class="text-primary cursor-pointer">Create new</a></div></div>
          // <div class="col-md-5"><img class="mt-n1 mr-1 w-100 gray_scale" src="/media/custom/gallery.png"/></div>
          // </div>

          <div class="col-auto pr-6 pl-0" key={index}>
            <div class="galler_card card card-custom mb-6">
              <div
                class="d_card w-100 no_hover d-flex align-content-between flex-wrap cursor-pointer"
                style={{
                  backgroundImage: item?.contentMediaUrl
                    ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                        item?.contentMediaUrl})`
                    : `url(${toAbsoluteUrl("/media/stock-900x600/23.jpg")})`,
                }}
                onClick={() => {
                  // window.open(
                  //   process.env.REACT_APP_AWS_S3_BASE_URL +
                  //     item?.contentMediaUrl
                  // );
                  // setFiletype(item.contentMediaUrl?.split(".").pop());
                  // setImage(item?.contentMediaUrl);
                  // setShowModal(true);
                }}
              >
                <div class="col-12 px-4 d-flex justify-content-end">
                  <a
                    // href={
                    //   process.env.REACT_APP_AWS_S3_BASE_URL +
                    //   item?.contentMediaUrl
                    // }
                    download
                    onClick={() => {
                      download({
                        file:
                          process.env.REACT_APP_AWS_S3_BASE_URL +
                          item?.contentMediaUrl,
                        filename:
                          item.campaignType +
                          "_" +
                          Math.floor(Math.random() * 1000000 + 1),
                      });
                    }}
                    class="btn btn-icon bg-dark-o-20 btn-sm h-25px w-25px mr-0"
                  >
                    <img
                      class="h-15px"
                      src="/media/svg/icons/custom/Icon_download.svg"
                    />
                  </a>
                  <a
                    onClick={() => {
                      setFiletype(item.contentMediaUrl?.split(".").pop());
                      setImage(item?.contentMediaUrl);
                      setShowModal(true);
                    }}
                    class="btn btn-icon bg-dark-o-20 btn-sm h-25px w-25px ml-2"
                  >
                    <img
                      class="h-10px"
                      src="/media/svg/icons/custom/Icon_eye.svg"
                      alt="view_icon"
                    />
                  </a>
                </div>

                <div class="col-12 px-4">
                  <p
                    class="w-100 mb-1 text-light font-size-xs text-truncate font-weight-SemiBold"
                    title="Create Contents"
                  >
                    {item?.campaign?.campaignName}
                  </p>
                </div>
              </div>
              <div class="d-flex d-flex align-items-center">
                <div class="flex-grow-1">
                  <div class="d-flex align-items-center mb-0 p-3">
                    <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                      <span
                        class="symbol-label"
                        style={{
                          backgroundImage: item?.creator?.profilePic
                            ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                                process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                                "/" +
                                item?.creator?.profilePic})`
                            : `url(${toAbsoluteUrl(
                                "/media/stock-600x600/img-9.jpg"
                              )})`,
                        }}
                      ></span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                      {item?.creator && (
                        <a
                          href="#"
                          class="text-dark font-weight-SemiBold text-hover-primary mb-0 font-size-xs text-capitalize"
                        >
                          {item?.creator?.firstName +
                            " " +
                            item?.creator?.lastName}
                        </a>
                      )}
                      <span class="text-dark font-size-xs font-weight-Medium">
                        <span class="opacity-50">
                          {DateFormatter(item?.updatedAt)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <img class=" mr-0 w-100 gray_scale" src="/media/custom/no_galley.svg" />
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {"Gallery Content"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" d-flex justify-content-center">
            <div>
              {fileType === "png" ||
              fileType === "jpg" ||
              fileType === "svg" ||
              fileType === "jpeg" ||
              fileType === "jfif" ? (
                <img
                  alt="content-file"
                  id="Media"
                  className="w-100 h-auto"
                  src={process.env.REACT_APP_AWS_S3_BASE_URL + image}
                />
              ) : (
                <video
                  className="iframe-resize w-100 h-auto"
                  title="Media"
                  id="Media"
                  // width="450"
                  // height="350"
                  src={process.env.REACT_APP_AWS_S3_BASE_URL + image}
                  frameBorder={0}
                  allowFullScreen
                  controls
                  controlsList="nodownload"
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-primary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {downloaderComponentUI}
    </>
  );
}
