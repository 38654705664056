import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSubheader } from "../../../_metronic/layout";
import { body2, body1, body3 } from "../../pages/helper/ModalBody";
import {
  getCreatorProfile,
  updateCreatorProfile,
} from "../_redux/commonActions";
import { ChooseCampaign } from "./components/ChooseCampaign";
import { MyCampaigns } from "./components/MyCampaigns";
import { MyApplications } from "./components/MyApplications";
import { Gallery } from "./components/Gallery";

export function DashboardView() {
  const suhbeader = useSubheader();
  const [screen, setScreen] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [inputCompanyName, setInputCompanyName] = useState("");
  const history = useHistory();
  suhbeader.setTitle("My Custom title");

  const {
    user,
    email,
    isVisibleModalInfo,
    profileToEdit,
    isRightSidebar,
  } = useSelector(
    (state) => ({
      user: state.auth.user,
      email: state?.common?.profileToEdit?.loginEmail,
      isVisibleModalInfo: state.common.isVisibleModalInfo,
      profileToEdit: state.common.profileToEdit,
      isRightSidebar: state.common.isRightSidebar,
    }),
    shallowEqual
  );
  const getmodalScreen = (screenNumber) => {
    switch (screenNumber) {
      case 1:
        return body1;
      case 2:
        return body2;
      case 3:
        return body3;
      default:
        return null;
    }
  };
  // const isModalOpen = localStorage.getItem("openModal");
  const isModalOpen = "false";
  const isBrandName = localStorage.getItem("enterBrandName");

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isModalOpen === "true" && isBrandName === "false") {
      screen <= 3 &&
        Swal.fire({
          type: "info",
          html: getmodalScreen(screen),
          // text:body1,
          // content: errors,
          showCloseButton: true,
          // showCancelButton: screen === 1 ? false :true,
          confirmButtonText: screen === 3 ? "Close" : "Next",
          confirmButtonColor: "#3699FF",
          // cancelButtonColor: "#EBEBED",
          confirmButtonClass: "btn_primary",
          // cancelButtonClass: "btn_secondary",
          cancelButtonText: "Back",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: true,
          allowOutsideClick: false,
          reversebuttonsText: "Back",
          showDenyButton: screen === 1 ? false : true,
          denyButtonColor: "#EBEBED",
          denyButtonClass: "btn_secondary",
          denyButtonText: "Back",
        }).then((resp) => {
          resp.isConfirmed && setScreen(screen + 1);
          resp.isDenied && setScreen(screen - 1);
          resp.isDismissed && localStorage.setItem("openModal", false);
          screen > 3 && localStorage.setItem("openModal", false);
        });
    }
  }, [isVisibleModalInfo, isModalOpen, dispatch, screen, isBrandName]);

  useEffect(() => {
    if (isBrandName === "true" || profileToEdit?.companyName === "") {
      setShowModal(true);
    }
  }, [isBrandName, profileToEdit]);

  const submitBrandName = () => {
    setloading(true);
    dispatch(
      updateCreatorProfile({
        companyName: inputCompanyName,
        loggedInUserId: user.userId,
        userId: user.userId,
      })
    ).then(() => {
      dispatch(
        getCreatorProfile({
          loggedInUserId: user.userId,
          userId: user.userId,
        })
      );
      setShowModal(false);
      setloading(false);
      localStorage.setItem("enterBrandName", false);
    });
  };

  useEffect(() => {
    dispatch(
      getCreatorProfile({
        userId: user?.userId,
        loggedInUserId: user?.userId,
        fields: [
          "profilePic",
          "firstName",
          "lastName",
          "loginEmail",
          "phoneNumber",
          "gender",
          "age",
          "creatorType",
          "companyName",
        ],
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCompanyName = () => {
    Swal.fire({
      title: "Oops",
      text: "Please enter your Brand Name",
      icon: "warning",
      allowOutsideClick: false,
      // showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/user-profile/personal-information");
      }
    });
  };

  return (
    <>
      <div class="row">
        <div class="col-xl-12">
          <h1 class="font-weight-Bold h3 mb-5 mx-0 text-dark pt-0  banner_title">
            What your business wants today?
          </h1>
          <div class="row service_card d-flex flex-nowrap overflow-auto">
            <ChooseCampaign />
          </div>
          <div class="row">
            <div class={` ${!isRightSidebar ? "col-md-3" : "col-md-4"}`}>
              <MyCampaigns />
            </div>
            <div class={` ${!isRightSidebar ? "col-md-9" : "col-md-8"}`}>
              <div class="card card-custom  gutter-b">
                <MyApplications />
              </div>
              <div class="col-md-12 px-0 mb-5 d-flex justify-content-between">
                <h4 class="font-weight-SemiBold">Gallery</h4>
                <a
                  class="font-weight-Medium text-dark cursor-pointer"
                  onClick={() => history.push("/post-analytics")}
                >
                  More
                </a>
              </div>
              <div class="row w-100 mb-6 d-flex flex-nowrap div_overflow overflow-auto mx-0">
                <Gallery />
              </div>
            </div>
          </div>
        </div>
        <Modal size="md" show={showModal} onHide={handleClose}>
          <Modal.Header>
            <h3>Brand Details</h3>
          </Modal.Header>
          <Modal.Body className="p-0 ml-8 mt-4">
            <div className="form-group row">
              <label className="col-md-1 col-form-label mt-1">Name</label>
              <div className="ml-6 col-md-8">
                <input
                  type="text"
                  placeholder="Enter your Brand Name"
                  className={`form-control form-control-lg form-control-solid`}
                  name="companyName"
                  value={inputCompanyName}
                  onChange={(e) => {
                    setInputCompanyName(e.target.value);
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer class="d-flex justify-content-center py-4">
            <button
              class="btn btn-primary mr-4"
              onClick={() => submitBrandName()}
            >
              {loading ? (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Save"
              )}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
