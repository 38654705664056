/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Invite from "./InvitePage";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import { useHistory } from "react-router-dom";

export function AuthPage() {
  const history = useHistory();
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid "
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-none flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg11.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-none flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <div class="d-flex justify-content-start">
                  <img
                    alt="Logo"
                    className="max-h-60px"
                    src={toAbsoluteUrl("/media/logos/logo-large.png")}
                  />
                  <h4 className="mt-11 ml-3 " style={{ color: "white" }}>
                    Beta
                  </h4>
                </div>
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column ">
                <label
                  style={{
                    lineHeight: 1.5,
                    fontSize: "24px",
                    fontWeight: "normal",
                  }}
                  className="mb-5 mt-15 text-white"
                >
                  Create brand stories which inspires your customers.
                </label>
                {/* <p className="font-weight-lighter text-white opacity-80">

                </p> */}
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2021-2022. 1000X™
                </div>
                <div className="d-flex">
                  {/* <Link
                    to={{
                      pathname: "https://www.1000x.club/legal.html",
                    }}
                    target="_blank"
                    className="text-white"
                  >
                    Privacy
                  </Link>
                  <Link
                    to={{
                      pathname: "https://www.1000x.club/legal.html",
                    }}
                    target="_blank"
                    className="text-white ml-10"
                  >
                    Legal
                  </Link> */}
                  <a
                    className="text-white ml-10"
                    // to="https://1000x.club/legal.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://1000x.club/brandHelp.html"
                    onClick={() =>
                      window.open(`https://1000x.club/brandHelp.html`)
                    }
                  >
                    How it works
                  </a>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid position-relative overflow-hidden bg-transparent content_area_login d-flex justify-content-center d-flex  d-flex align-items-center">
            {/* begin::Content header*/}

            {/*end::Content header */}

            {/* begin::Content body */}
            <div className="d-flex flex-center mt-0 mt-md-30 mt-lg-0 px-8 py-4 flex-wrap d-flex bg-light w-350px align-items-start">
              <div class="d-none justify-content-center col-md-12 text-center">
                <img
                  alt="Logo"
                  className="max-h-40px"
                  src={toAbsoluteUrl("/media/logos/logo-dark.png")}
                />
                <h4 className="mt-6 ml-3 text-dark" style={{ color: "black" }}>
                  Beta
                </h4>
              </div>
              <Switch>
                <ContentRoute path="/auth/login/:id" component={Login} />
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/invite/:inviteCode/:inviteId"
                  component={Invite}
                />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect to="/auth/login" />
              </Switch>
              {/* <div className="d-flex justify-content-center py-5 px-3 font-size-lg">
                <span className="font-weight-bold text-dark">
                  {history.location.pathname === "/auth/registration"
                    ? "Already a member ?"
                    : "Not a member ?"}
                </span>

                {history.location.pathname === "/auth/registration" ? (
                  <>
                    <Link
                      to="/auth/login"
                      className="font-weight-bold ml-2"
                      id="kt_login_auth_login"
                    >
                      Sign In
                    </Link>
                  </>
                ) : (
                  <Link
                    to="/auth/registration"
                    className="font-weight-bold ml-2"
                    id="kt_login_signup"
                  >
                    <b>Sign Up for free</b>
                  </Link>
                )}
              </div> */}
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 d-none font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021-2022. 1000X™
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                {/* <Link
                  to={{
                    pathname: "https://www.1000x.club/legal.html",
                  }}
                  target="_blank"
                  className="text-dark-75 text-hover-primary"
                >
                  Privacy
                </Link>
                <Link
                  to={{
                    pathname: "https://www.1000x.club/legal.html",
                  }}
                  target="_blank"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link> */}
                <a
                  className="text-blue ml-10 align align-self-center mr-8"
                  // to="https://1000x.club/legal.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://1000x.club/brandHelp.html"
                  onClick={() =>
                    window.open(`https://1000x.club/brandHelp.html`)
                  }
                >
                  How it works
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
