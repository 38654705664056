import axios from "axios";
export const CHECK_USER_FOR_FORGOT_PASSWORD_URL =
  process.env.REACT_APP_API_PUBLIC_URL +
  "v1/brand/signup/checkUserForForgetpassword";
export const UPDATE_PASSWORD_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/signup/updateNewPassword";
export const LOGIN_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/login/loginUser";
export const LOGIN_OR_SIGNUP_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/login/loginOrSignUpUser";
export const GENERATE_OTP_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/login/generateOtpForLogin";
export const BASE_URL = "https://backendapi.dev.projectx.iinerds.com/";
export const VERIFY_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/signup/verifyCode";
export const REGISTER_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/signup/register";
export const SEND_OTP_FOR_REGISTERATION =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/signup/sendPhoneOtp";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const RENEW_ACCESS_TOKEN =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/auth/renewAccessToken";
export const GOOGLE_SIGN_IN_URL =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/signup/googleSignupOrLogin";
export const SIGN_UP_REGISTRATION_OTP =
  process.env.REACT_APP_API_PUBLIC_URL +
  "v1/brand/signup/completeRegistrationWithOtp";
export const GET_TOKEN_DATA_FOR_WEB =
  process.env.REACT_APP_API_PUBLIC_URL + "v1/brand/login/getBrandTokenData";

export const ME_URL = "api/me";

export function login(values) {
  return axios.post(LOGIN_URL, {
    // email: values?.email,
    // phoneNumber: values?.phoneNumber,
    // userPsw: values?.password,
    ...values,
    trackingPriority: 0,
    frontendAction: "brand.login.loginUser",
  });
}

export function loginOrSignUp(values) {
  return axios.post(LOGIN_OR_SIGNUP_URL, {
    // email: values?.email,
    // phoneNumber: values?.phoneNumber,
    // userPsw: values?.password,
    ...values,
    trackingPriority: 0,
    frontendAction: "brand.login.loginOrSignUpUser",
  });
}

export function getTokenDataForWeb(values) {
  return axios.post(GET_TOKEN_DATA_FOR_WEB, {
    ...values,
    trackingPriority: 0,
    frontendAction: "brand.login.getBrandTokenData",
  });
}

export function generateOtpLogin(values) {
  return axios.post(GENERATE_OTP_URL, {
    email: values?.email,
    phoneNumber: values?.phoneNumber,
    phoneCode: values?.phoneCode,
    trackingPriority: 0,
    frontendAction: "brand.login.generateOtpForLogin",
  });
}

export function verifyBrand(verify) {
  let verifyObj = {
    trackingPriority: 1,
    frontendAction: "VERIFY-BRAND",
    inviteCode: verify.code,
  };
  if (
    verify.username &&
    verify.username.length === 10 &&
    !isNaN(verify.username)
  ) {
    verifyObj.phoneNumber = verify.username;
    verifyObj.countryCode = "+91";
  } else {
    verifyObj.email = verify.username;
  }
  return axios.post(VERIFY_URL, verifyObj);
}

export function register(brandRegisterObj) {
  let phoneNumber = brandRegisterObj?.phoneNumber?.replace(
    brandRegisterObj?.phoneCode,
    ""
  );
  let registerBody = {
    trackingPriority: 1,
    frontendAction: "REGISTER_BRAND",
    email: brandRegisterObj?.loginEmail,
    phoneNumber,
    countryCode: brandRegisterObj?.phoneCode,
    workEmail: brandRegisterObj?.workEmail,
    firstName: brandRegisterObj?.firstName,
    lastName: brandRegisterObj?.lastName,
    companyName: brandRegisterObj?.companyName,
    userPsw: brandRegisterObj?.password,
    userId: brandRegisterObj?.userId,
    loggedInUserId: brandRegisterObj?.userId,
  };
  return axios.post(REGISTER_URL, registerBody);
}

export function sendPhoneOtpForRegistration(brandRegisterObj) {
  let phoneNumber = brandRegisterObj?.phoneNumber?.replace(
    brandRegisterObj?.phoneCode,
    ""
  );
  let registerBody = {
    trackingPriority: 1,
    frontendAction: "REGISTER_BRAND",
    email: brandRegisterObj?.email,
    phoneNumber,
    countryCode: brandRegisterObj?.phoneCode,
    workEmail: brandRegisterObj?.workEmail,
    firstName: brandRegisterObj?.firstName,
    lastName: brandRegisterObj?.lastName,
    companyName: brandRegisterObj?.companyName,
    userPsw: brandRegisterObj?.password,
    userId: brandRegisterObj?.userId,
    loggedInUserId: brandRegisterObj?.userId,
  };
  return axios.post(SEND_OTP_FOR_REGISTERATION, registerBody);
}

export function completeRegistrationWithOtp(values) {
  return axios.post(SIGN_UP_REGISTRATION_OTP, {
    frontendAction: "Brands.signup.completeRegistrationWithOtp",
    trackingPriority: 0,
    ...values,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function renewAccessToken(refreshToken) {
  return axios.post(RENEW_ACCESS_TOKEN, refreshToken);
}

export function verifyUser(values) {
  return axios.post(CHECK_USER_FOR_FORGOT_PASSWORD_URL, {
    email: values?.email,
    phoneNumber: values?.phoneNumber,
    countryCode: values?.countryCode,
    trackingPriority: 0,
    frontendAction: "brand.signup.checkUserForForgetpassword",
  });
}

export function updatePassword(values) {
  return axios.post(UPDATE_PASSWORD_URL, {
    userId: values?.userId,
    email: values?.email,
    forgotCode: values?.forgotCode,
    phoneNumber: values?.phoneNumber,
    userPsw: values?.password,
    countryCode: values?.phoneCode,
    trackingPriority: 0,
    frontendAction: "brand.signup.checkUserForForgetpassword",
  });
}

export function googleSignInorLogin(values) {
  return axios.post(GOOGLE_SIGN_IN_URL, {
    token: values.token,
    frontendAction: "brand.google.googleSignupOrLogin",
    trackingPriority: 0,
  });
}
