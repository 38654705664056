import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHistory, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import moment from "moment";

export function MessageInbox({ chatInboxArray }) {
  const history = useHistory();
  return (
    <>
      <div class="mb-15">
        <div class="card card-custom card-shadowless gutter-b bg-light mb-6">
          <div class="card-header border-0 ">
            <h3 class="card-title font-weight-bolder text-dark">
              Message Inbox
            </h3>
            <div class="card-toolbar">
              <div class="dropdown dropdown-inline">
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <ul class="navi navi-hover">
                    <li class="navi-header pb-1">
                      <span class="text-primary text-uppercase font-weight-bold font-size-sm">
                        Add new:
                      </span>
                    </li>
                    <li class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-writing"></i>
                        </span>
                        <span class="navi-text">File</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0 mob_view_h max-h-550px overflow-auto px-5 pb-5 pt-0">
            {chatInboxArray &&
              chatInboxArray.map((item, index) => (
                <div
                  class="d-flex align-items-center flex-nowrap mb-4 m-0 ml-5"
                  key={index}
                >
                  <div class="symbol symbol-50 mr-5 symbol-light-success">
                    <span
                      class="symbol-label"
                      style={{
                        backgroundImage: item?.toCreatorUserDetails?.profilePic
                          ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                              process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                              "/" +
                              item?.toCreatorUserDetails?.profilePic})`
                          : `url(${toAbsoluteUrl(
                              "/media/stock-600x600/img-8.jpg"
                            )})`,
                      }}
                    ></span>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 mr-0" style={{width:'40%'}}>
                    <div class="d-flex align-items-center justify-content-between">
                      <a
                        href="#"
                        class="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1 text-truncate "
                        title={
                          item?.toCreatorUserDetails?.firstName +
                          " " +
                          item?.toCreatorUserDetails?.lastName
                        }
                        onClick={() => {
                          history.push(
                            `/campaigns/list/${item?.campaignId}/view?tab=messages`
                          );
                        }}
                      >
                        {item?.toCreatorUserDetails?.firstName +
                          " " +
                          item?.toCreatorUserDetails?.lastName}
                      </a>
                    </div>
                    <span class="text-muted font-weight-bold text-truncate message_text">
                      {item.messageType === "M_TEXT" ? item.messageValue : null}
                      {item.messageType === "M_VIDEO" ? (
                        <SVG
                          style={{
                            fill: "#B5B5C3",
                            height: "17px",
                            width: "17px",
                          }}
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/video-camera.svg"
                          )}
                        ></SVG>
                      ) : null}
                      {item.messageType === "M_IMAGE" ? (
                        <SVG
                          style={{
                            fill: "#B5B5C3",
                            height: "17px",
                            width: "17px",
                          }}
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/image.svg"
                          )}
                        ></SVG>
                      ) : null}
                      {item.messageType === "M_AUDIO" ? (
                        <SVG
                          style={{
                            fill: "#B5B5C3",
                            height: "17px",
                            width: "17px",
                          }}
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/microphone.svg"
                          )}
                        ></SVG>
                      ) : null}
                    </span>
                  </div>
                  <div class="d-flex flex-row mb-7">
                    <span className="text-muted font-size-sm max-w-100px">
                      {moment(item.lastMessageAt)
                        .startOf("hour")
                        .fromNow(".") !== "Invalid date"
                        ? moment(item.lastMessageAt)
                            .startOf("hour")
                            .fromNow(".")
                        : null} ago
                    </span>
                  </div>
                </div>
              ))}
            {chatInboxArray?.length < 1 && (
              <div class="card-body pt-0 pb-0 px-0">
                <div class="row no_data_campaign text-center mx-0">
                  <h6 class="mx-auto font-weight-bold px-5 w-100 mb-0 mt-6 text-dark-50 d-none">
                    No Active campaigns yet
                  </h6>
                  {/* <div class="w-100 mt-5">
                    <a class="text-primary  cursor-pointer">Create new</a>
                  </div> */}
                  <img
                    class=" mr-0 w-100 gray_scale"
                    src="/media/custom/no_inbox.svg"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
