import * as requestFromServer from "./galleryCrud";
import { gallerySlice, callTypes } from "./gallerySlice";
// import { Toaster } from "../../../components/Toaster";

const { actions } = gallerySlice;

export const getGalleryContents = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getGalleryContents(queryParams)
    .then((response) => {
      const { total: totalCount, rows: medias,totalPages } = response.data.data;
      dispatch(actions.mediaFetched({ totalCount, medias,totalPages }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find medias";
      dispatch(actions.mediaFetched({ totalCount: 0, medias: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getMediaDownloadURL = (queryParams, viewStatus = false) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMediaDownloadURL(queryParams)
    .then((response) => {
      viewStatus && window.open(response.data.data.url);
      dispatch(actions.downloadMediaUrlFetched(response.data.data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find medias";
      dispatch(actions.downloadMediaUrlFetched({ mediaURL: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDownloadURL = (queryParams) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMediaDownloadURL(queryParams)
    .then((response) => {
      window.open(response.data.data.url);
      dispatch(actions.downloadMediaUrlFetched(response.data.data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find medias";
      dispatch(actions.downloadMediaUrlFetched({ mediaURL: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getReferenceDownloadURL = (queryParams, viewStatus = false) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getReferenceDownloadURL(queryParams)
    .then((response) => {
      viewStatus && window.open(response.data.data.url);
      dispatch(actions.downloadMediaUrlFetched(response.data.data));
    })
    .catch((error) => {
      error.clientMessage = "Can't find medias";
      dispatch(actions.downloadMediaUrlFetched({ mediaURL: null }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clearDownloadURL = (queryParams) => (dispatch) => {
  dispatch(actions.downloadMediaUrlFetched({}));
};
