import { createSlice } from "@reduxjs/toolkit";
import { isArray } from "lodash";
export const chatModuleInitialState = {
  isApiInprogressFlag: false,
  inboxLoading:false,
  chatInboxArray: [],
  campaignSpecificChatInox: [],
  chatMessagesArray: [],
  creatorDetails: {},
  totalMessages: "",
};

export const ChatModuleSlice = createSlice({
  name: "chatModule",
  initialState: chatModuleInitialState,
  reducers: {
    toggleIsApiInprogressFlag: (state, action) => {
      state.isApiInprogressFlag = Boolean(action.payload);
    },

    loadInbox: (state, action) => {
      state.inboxLoading = true;
    },

    toggleMessageSend: (state, action) => {
      state.messageSend = Boolean(action.payload);
    },
    updateChatInboxArray: (state, action) => {
      if (action?.payload && isArray(action?.payload)) {
        for (const item of action?.payload) {
          state.chatInboxArray.push(item);
        }
      }
    },

    getSpecificCampaignInbox: (state, action) => {
      state.campaignSpecificChatInox = action.payload.campaignSpecificChatInox;
      state.inboxLoading = false;
    },

    resetInbox: (state, action) => {
      state.campaignSpecificChatInox = [];
    },
    updateChatMessagesArray: (state, action) => {
      let chatMessagesArray = action.payload.reverse();
      state.chatMessagesArray = chatMessagesArray.concat(
        state.chatMessagesArray
      );
    },
    updateTotalMessages: (state, action) => {
      let totalMessages = action.payload;
      state.totalMessages = totalMessages;
    },
    resetMessages: (state, action) => {
      state.chatMessagesArray = [];
    },

    updateIncomingMessage: (state, action) => {
      state.chatMessagesArray.push(action.payload);
    },

    clearPreviousMessage: (state, action) => {
      state.chatMessagesArray = [];
    },

    campaignDetailsFetched: (state, action) => {
      let { campaignDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.campaignDetails = campaignDetails;
    },

    creatorDetailsFetched: (state, action) => {
      let { creatorDetails } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.creatorDetails = creatorDetails;
    },

    // applicationDetailsFetched: (state, action) => {
    //   let { applicationDetails } = action.payload;
    //   state.applicationDetails = applicationDetails;
    // },

    // applicationsFetched: (state, action) => {
    //   let { applications, totalCount } = action.payload;
    //   state.listLoading = false;
    //   state.error = null;
    //   state.entities = applications;
    //   state.totalCount = totalCount;
    // },
  },
});
