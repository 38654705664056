import axios from "axios";

export const GET_NOTIFICATIONS = "v1/notification/getNotification";
export const GET_UNREAD_NOTIFICATIONS = "v1/notification/getUnreadNotificationCount";
export const CLEAR_NOTIFICATIONS = "v1/notification/clearNotification";
export const SEND_APP_NOTIFICATIONS = "v1/notification/sendAppNotification";
export const SEND_CHAT_NOTIFICATIONS = "v1/notification/sendChatNotification";


export function getNotifications(params) {
  return axios.post(GET_NOTIFICATIONS, {
    ...params,
    frontendAction: "notification.getNotification",
    trackingPriority: 0,
  });
}

export function getUnreadNotificationCount(params) {
  return axios.post(GET_UNREAD_NOTIFICATIONS, {
    ...params,
    frontendAction: "notification.getUnreadNotificationCount",
    trackingPriority: 0,
  });
}

export function clearNotification(params) {
  return axios.post(CLEAR_NOTIFICATIONS, {
    ...params,
    frontendAction: "notification.clearNotification",
    trackingPriority: 0,
  });
}

export function sendAppNotification(params) {
  return axios.post(SEND_APP_NOTIFICATIONS, {
    ...params,
    // frontendAction: "notification.clearNotification",
    trackingPriority: 0,
  });
}

export function sendChatNotification(params) {
  return axios.post(SEND_CHAT_NOTIFICATIONS, {
    ...params,
    // frontendAction: "notification.clearNotification",
    trackingPriority: 0,
  });
}


