import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import PhoneInput from "react-phone-input-2";
import { verifyUser,updatePassword } from "../_redux/authCrud";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const initialValues = {};

function ForgotPassword(props) {
  const history = useHistory();
  const [usrId, setUsrId] = useState("");
  const [type, setType] = useState("userVeification");


  const UserVerification = () => {
    const { intl } = props;
    const [phoneStatus, setPhoneStatus] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isRequested, setIsRequested] = useState(false);
    const ForgotPasswordEmailSchema = Yup.object().shape({
      email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ),
    });

    const ForgotPasswordPhoneSchema = Yup.object().shape({
      phoneNumber: Yup.string()
      // .matches(phoneRegExp, "Phone number is not valid")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      countryCode: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ).nullable()
    });

    const enableLoading = () => {
      setLoading(true);
    };

    const disableLoading = () => {
      setLoading(false);
    };

    const getInputClasses = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }

      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }

      return "";
    };

    const formik = useFormik({
      initialValues,
      validationSchema: phoneStatus? ForgotPasswordPhoneSchema : ForgotPasswordEmailSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        enableLoading();
        verifyUser(values)
          .then(({ data }) => {
            setType("setPasw");
            disableLoading();
            setSubmitting(false);
            
            setUsrId(data.data[0].userId);
            
          })
          .catch((err) => {
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
            disableLoading();
          });
      },
    });
    return (
      <>
        {isRequested && <Redirect to="/auth" />}
        {!isRequested && (
          <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className=" mb-lg-10">
              <h2 className="font-size-h2" style={{fontWeight: "bold"}} >Forgotten Password ?</h2>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              {/* <div className="form-group fv-plugins-icon-container">
                <input
                  type="radio"
                  name="loginType"
                  value="phoneVia"
                  onChange={() => {
                    formik.setFieldValue("email", undefined);
                    setPhoneStatus(true);
                  }}
                  defaultChecked = {true}
                />
                <label className="ml-2" htmlFor="phoneWise">
                  Mobile Number
                </label>
                <input
                  className="ml-5"
                  type="radio"
                  name="loginType"
                  value="emailVia"
                  onChange={() => {
                    formik.setFieldValue("phoneNumber", undefined);
                    setPhoneStatus(false);
                  }}
                />
                <label className="ml-2" htmlFor="emailVia">
                  Email
                </label>
              </div> */}
              {phoneStatus ? (
                <div className="form-group fv-plugins-icon-container">
                  <PhoneInput
                    enableAreaCodes={true}
                    inputProps={{
                      name: "phoneNumber",
                      required: true,
                    }}
                    placeholder="Enter phone number"
                    country={"in"}
                    preferredCountries={["ae", "sa"]}
                    countryCodeEditable={false}
                    enableSearch={true}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                    }}
                    onChange={(value, countryData) => {
                      let countryCode = `+${countryData.dialCode}`;
                      let phoneNumber = value.replace(countryData.dialCode, "");
                      formik.setFieldValue("phoneNumber", phoneNumber);
                      formik.setFieldValue("countryCode", countryCode);
                    }}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.phoneNumber}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Email"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                    value={formik.values.email || ""}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
              )}
              <div className="form-group d-flex flex-wrap ">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        )}
      </>
    );
  };

  const SetPassword = () => {
    const { intl } = props;
    const PasswordSchema = Yup.object().shape({
      password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        ),
        changepassword: Yup.string()
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD",
          })
        )
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password didn't match"
          ),
        }),
        forgotCode:Yup.string().required("enter verification code"),
    });
    const getInputClasses = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }
  
      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }
  
      return "";
    };
    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: PasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      updatePassword({...values,userId:usrId})
        .then(({ data }) => { 
          Swal.fire({
            title: "Your account password has been successfully changed !",
            text:
              "Login with your registered mobile number or email and password to continue.",
            icon: "Success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            reverseButtons: false,
          }).then(() => history.push("/auth/login"));
        })
        .catch((err) => {
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          
        });
    },

    });
    return (
      <>
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your New Password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
            >
          {/* enter password */}
          <div className="form-group fv-plugins-icon-container">
            
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            value={formik.values.password || ""}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* confirm password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
            value={formik.values.changepassword || ""}
          />
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Enter verification code send to registered email/phone"
            type="forgotCode"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "forgotCode"
            )}`}
            name="forgotCode"
            {...formik.getFieldProps("forgotCode")}
            value={formik.values.forgotCode || ""}
          />
        </div>
        <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid 
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Done</span>
          </button>
        </form>
        </div>
      </>
    );
  };
  return (
    <>
      {type === "userVeification" ? <UserVerification /> : <SetPassword/> }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
