/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useHistory } from "react-router-dom";
import { MyCampaigns } from "../../../../../app/modules/dashboard/components/MyCampaigns";
import { setRightSideBar } from "../../../../../app/modules/_redux/commonActions";
import { useDispatch } from "react-redux";

export function HeaderMenu({ layoutProps }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.header }),
  //   shallowEqual
  // );
  // console.log('currentState', currentState)
  // const branch = currentState?.entities?.title && currentState.entities.title;
  // console.log("branch", branch);

  return (
    <div
      id="kt_header_menu"
      class="header-menu header-menu-mobile header-menu-layout-default"
    >
      <div class="brand flex-column-auto" id="kt_brand">
        <a
          onClick={() => {
            history.push("/dashboard/view");
            dispatch(setRightSideBar(true));
          }}
          class="brand-logo cursor-pointer"
        >
          <img
            alt="Logo"
            src="/media/logo/Blue.svg"
            class="max-h-30px mr-8 mt-2"
          />
        </a>
      </div>
      <ul class="menu-nav ">
        <li
          class={`menu-item   menu-item-open menu-item-not-hightlighted ${getMenuItemActive(
            "/dashboard/view",
            true
          )} ${getMenuItemActive("/dashboard/view#", true)}`}
          aria-haspopup="true"
        >
          <a
            class="menu-link active"
            onClick={() => {
              history.push("/dashboard/view");
              dispatch(setRightSideBar(true));
            }}
            aria-current="page"
          >
            <span class="menu-text font-weight-SemiBold">Dashboard</span>
          </a>
        </li>
        {/* <li
          class={`menu-item ${getMenuItemActive(
            "/campaigns/list",
            true
          )} ${getMenuItemActive("/campaigns/list#", true)}`}
          aria-haspopup="true"
        >
          <a class="menu-link" href="/campaigns/list">
            <span class="menu-text font-weight-SemiBold">My Campaigns</span>
          </a>
        </li> */}
        <li
          class={`menu-item menu-item-submenu ${getMenuItemActive(
            "/activeCampaign",
            true
          )} ${getMenuItemActive(
            "/completedCampaign",
            true
          )} ${getMenuItemActive("/pendingCampaign", true)}`}
          data-menu-toggle="click"
          aria-haspopup="true"
        >
          <a
            class="menu-link cursor-pointer"
            onClick={() => history.push(`/activeCampaign`)}
          >
            <span class="menu-text font-weight-SemiBold">Campaign</span>
          </a>
          {/* <div
            class="menu-submenu menu-submenu-classic menu-submenu-left"
            data-hor-direction="menu-submenu-left"
          >
            <ul class="menu-subnav">
              <li class="menu-item" aria-haspopup="true">
                <a class="menu-link"  onClick={()=>history.push("/activeCampaign")}>
                  <span class="menu-text">Active</span>
                </a>
              </li>
              <li class="menu-item" aria-haspopup="true">
                <a class="menu-link"  onClick={()=>history.push("/pendingCampaign")}>
                  <span class="menu-text">Pending</span>
                </a>
              </li>
              <li class="menu-item" aria-haspopup="true">
                <a class="menu-link" onClick={()=>history.push("/completedCampaign")}>
                  <span class="menu-text">Completed</span>
                </a>
              </li>
            </ul>
          </div> */}
        </li>
        <li
          class={`menu-item ${getMenuItemActive(
            "/applications/list",
            true
          )} ${getMenuItemActive("/applications/list#", true)}`}
          aria-haspopup="true"
        >
          <a
            class="menu-link cursor-pointer"
            onClick={() => history.push("/applications/list")}
          >
            <span class="menu-text font-weight-SemiBold">Application</span>
          </a>
        </li>
        <li class="menu-item btn disabled d-none" aria-haspopup="true">
          <a class="menu-link btn disabled">
            <span class="font-weight-SemiBold">Proposals</span>
          </a>
        </li>
        <li class="menu-item btn disabled d-none" aria-haspopup="true">
          <a class="menu-link btn disabled">
            <span class="font-weight-SemiBold">Services</span>
          </a>
        </li>
        <li
          class={`menu-item ${getMenuItemActive(
            "/post-analytics",
            true
          )} ${getMenuItemActive("/post-analytics#", true)}`}
          aria-haspopup="true"
        >
          <a class="menu-link" onClick={() => history.push("/post-analytics")}>
            <span class="menu-text font-weight-SemiBold">Gallery</span>
          </a>
        </li>
        <li class="menu-item btn disabled d-none" aria-haspopup="true">
          <a class="menu-link btn disabled">
            <span class="font-weight-SemiBold">Community</span>
          </a>
        </li>
      </ul>
    </div>
  );
}
