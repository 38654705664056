import axios from "axios";

export const GET_CAMPAIGNS = "v1/brand/campaigns/list";
export const GET_CAMPAIGN_DETAILS = "v1/brand/campaigns/details";
export const GET_APPLICATION_DETAILS = "v1/brand/campaign/applications/list";
export const GET_CAMPAIGN_APPLICATIONS = "v1/brand/campaign/applications/list";
export const GET_APPLICATION_CONTENT =
  "v1/brand/campaign/application/contents/list";
export const CLOSE_CAMPAIGN = "v1/brand/campaigns/closeCampaign";
export const GET_COMPLETED_CAMPAIGNS = "v1/brand/campaigns/completedCampaigns";
export const GET_CAMPAIGN_TEMPLATES = "v1/brand/campaign/templates/list";
export const SEARCH_CAMPAIGN_TEMPLATES =
  "v1/brand/campaign/templates/templateSearch";
export const INCREASE_TEMPLATE_COUNT =
  "v1/brand/campaign/templates/incrementViewCount";
export const GET_CAMPAIGN_CATEGORY = "v1/brand/campaign/templates/categoryList";
export const GET_CAMPAIGN_TEMPLATE_WITH_CATEGORY_FILTER =
  "v1/brand/campaign/templates/filterTemplateWithCategory";
export const SAVE_CAMPAIGN_TEMPLATE = "v1/brand/campaign/templates/create";
export const LIST_FILTER_LEVELS =
  "v1/brand/campaign/templates/listFilterLevels";
export const LIST_FILTERS_AND_SUBFILTERS =
  "v1/brand/campaign/templates/listFiltersAndSubFilters";
export const LIST_SUB_FILTERS = "v1/brand/campaign/templates/listSubFilters";
export const REMOVE_CAMPAIGN_TEMPLATE = "v1/brand/campaign/templates/remove";
export const GET_TEMPLATE_WITH_ID =
  "v1/brand/campaign/templates/getTemplateWithId";

// GET =>  get signed Url
export function getCampaigns(params) {
  return axios.post(GET_CAMPAIGNS, {
    ...params,
    // expiryFilter: new Date().toISOString(),
    frontendAction: "creator.campaigns.list",
    trackingPriority: 0,
  });
}

export function getCampaignDetails(params) {
  return axios.post(GET_CAMPAIGN_DETAILS, {
    ...params,
    frontendAction: "creator.campaigns.details",
    trackingPriority: 0,
  });
}

export function getApplicationDetails(params) {
  return axios.post(GET_APPLICATION_DETAILS, {
    ...params,
    frontendAction: "brand.campaign.applications.list",
    trackingPriority: 0,
  });
}

export function listCampaignApplications(params) {
  return axios.post(GET_CAMPAIGN_APPLICATIONS, {
    ...params,
    frontendAction: "admin.campaign.applications.list",
    trackingPriority: 0,
  });
}

export function getApplicationContent(params) {
  return axios.post(GET_APPLICATION_CONTENT, {
    ...params,
    frontendAction: "admin.campaign.application.contents.list",
    trackingPriority: 0,
  });
}

export function closeCampaign(params) {
  return axios.post(CLOSE_CAMPAIGN, {
    ...params,
    frontendAction: "brand.campaigns.closeCampaign",
    trackingPriority: 0,
  });
}

export function completedCampaignsFetched(params) {
  return axios.post(GET_COMPLETED_CAMPAIGNS, {
    ...params,
    // expiryFilter: new Date().toISOString(),
    frontendAction: "creator.campaigns.list",
    trackingPriority: 0,
  });
}

export function getCampaignTemplates(params) {
  return axios.post(GET_CAMPAIGN_TEMPLATES, {
    ...params,
    frontendAction: "brand.campaign.templates.list",
    trackingPriority: 0,
  });
}

export function searchCampaignTemplates(params) {
  return axios.post(SEARCH_CAMPAIGN_TEMPLATES, {
    ...params,
    frontendAction: "brand.campaign.templates.templateSearch",
    trackingPriority: 0,
  });
}
export const removeCampaignTemplateCrud = (params) => {
  return axios.post(REMOVE_CAMPAIGN_TEMPLATE, {
    ...params,
    frontendAction: "brand.campaign.templates.remove",
    trackingPriority: 0,
  });
};

export function increaseTemplateCount(params) {
  return axios.post(INCREASE_TEMPLATE_COUNT, {
    ...params,
    frontendAction: "brand.campaign.templates.incrementViewCount",
    trackingPriority: 0,
  });
}

export function getTemplateDetailsCrud(params) {
  return axios.post(GET_TEMPLATE_WITH_ID, {
    ...params,
    frontendAction: "brand.campaign.templates.getTemplateWithId",
    trackingPriority: 0,
  });
}

export function getCampaignCategory(params) {
  return axios.post(GET_CAMPAIGN_CATEGORY, {
    ...params,
    frontendAction: "brand.campaign.templates.categoryList",
    trackingPriority: 0,
  });
}

export function filterTemplateWithCategory(params) {
  return axios.post(GET_CAMPAIGN_TEMPLATE_WITH_CATEGORY_FILTER, {
    ...params,
    frontendAction: "brand.campaign.templates.filterTemplateWithCategory",
    trackingPriority: 0,
  });
}

export function saveCampaignTemplate(params) {
  return axios.post(SAVE_CAMPAIGN_TEMPLATE, {
    ...params,
    frontendAction: "brand.campaign.templates.create",
    trackingPriority: 0,
  });
}

export function listFilterLevels(params) {
  return axios.post(LIST_FILTER_LEVELS, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.campaign.templates.listFilterLevels",
  });
}

export function listFilterAndSubFilters(params) {
  return axios.post(LIST_FILTERS_AND_SUBFILTERS, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.campaign.templates.listFiltersAndSubFilters",
  });
}

export function listSubFilters(params) {
  return axios.post(LIST_SUB_FILTERS, {
    ...params,
    trackingPriority: 0,
    frontendAction: "brand.campaign.templates.listSubFilters",
  });
}
