/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { MessageInbox } from "./messageInbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as actions from "../../../../app/modules/_redux/commonActions";
import { ChatModuleSelector } from "../../../../app/modules/ChatModule/_redux/ChatModuleSelector";
import {
  unreadNotificationCount,
  listNotifications,
  updateUnreadNotificationCount,
} from "../extras/dropdowns/notifications/_redux/NotificationActions";
import { getPage } from "./sideBarHelper";
import {
  loadInbox,
  resetInbox,
} from "../../../../app/modules/ChatModule/_redux/ChatModuleActions";
import SideBarCampaigns from "./SideBarCampaigns";
import HeaderUserDetails from "../header/header-menu/HeaderUserDetails";
// import { getPortfolio } from "../../../../app/modules/campaignApplications/_redux/applicationCrud";

export function SideBar({ component, setComponent }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isApiInprogressFlag, user, chatInboxArray } = useSelector(
    ChatModuleSelector,
    shallowEqual
  );
  const {
    userDetails,
    unreadNotificationCounts,
    notificationsList,
    list,
    viewSideBar,
    profileToEdit,
    isVisibleModalInfo,
    portfolioDetails,
  } = useSelector(
    (state) => ({
      userDetails: state.auth.user,
      notificationsList: state.notificationModule.notificationsList,
      unreadNotificationCounts:
        state.notificationModule.unreadNotificationCount,
      list: state.notificationModule.list,
      viewSideBar: state.common.isVisibleSideBar,
      profileToEdit: state.common.profileToEdit,
      isVisibleModalInfo: state.common.isVisibleModalInfo,
      portfolioDetails: state.application.portfolioDetails,
    }),
    shallowEqual
  );

  const pageSize = 10;
  const page = 1;
  const loggedInUserId = user.userId;
  const fetchMoreInInboxData = () => {
    dispatch(loadInbox(user.userId, page, pageSize, loggedInUserId));
  };

  useEffect(() => {
    dispatch(
      unreadNotificationCount({
        loggedInUserId,
        appInfo: "BRAND",
      })
    );
    location.pathname !== "/messagings/inbox" && fetchMoreInInboxData();
    dispatch(
      actions.getCreatorProfile({
        userId: user?.userId,
        loggedInUserId: user?.userId,
        fields: [
          "profilePic",
          "firstName",
          "lastName",
          "companyName",
          "loginEmail",
          "phoneNumber",
          "gender",
          "age",
          "creatorType",
          "aboutUser",
          "areasOfIneterst",
          "number",
          "phoneCode",
          "instagramFollowerCount",
          "youtubeSubscriptionCount",
          "instagramId",
          "youtubeId",
          "paymentAccountId",
          "primaryModeOfRegistration",
        ],
      })
    );
    // dispatch(
    //   getPortfolio({
    //     page: 1,
    //     pageSize: 6,
    //     loggedInUserId: loggedInUserId,
    //     userId: loggedInUserId,
    //     fileType: "IMAGE",
    //   })
    // );
    return () => {
      dispatch(resetInbox());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for changing the sidebar according to pathname
  useEffect(() => {
    // setComponent(component);
    let campaignUrl = new RegExp("/campaigns/list");
    let viewUrl = new RegExp("/view");
    campaignUrl.test(window.location.href) &&
      viewUrl.test(window.location.href) &&
      setComponent("campaigns");
  }, [location.pathname]);

  return (
    <>
      <div
        className={`sidebar ${
          viewSideBar ? "mob_active " : ""
        }  sidebar-right d-flex flex-row-auto flex-column`}
        id="kt_sidebar"
      >
        <HeaderUserDetails setComponent={setComponent} />
        {/*user details end*/}
        {/*side bar conetnt*/}
        <div class="sidebar-content flex-column-fluid pb-10 pt-9 px-5 px-lg-10">
          {/* <div class="d-flex align-items-center bg-light-primary  rounded p-5 mb-5">
            <span class="svg-icon svg-icon-danger mr-5">
              <span class="svg-icon svg-icon-lg">
                <img src="/media/svg/icons/General/Half-star.svg" />
              </span>
            </span>
            <div class="d-flex flex-column flex-grow-1 mr-0">
              <a
                href="#"
                class="font-weight-SemiBold text-dark-75 text-hover-primary font-size-md mb-1"
              >
                Complete your profile to start engaging with the brands.
              </a>
            </div>
          </div> */}
          {/*Notification*/}
          {component === "notification" && (
            <div className="card card-custom  gutter-b bg-light mb-4">
              <div className="card-header border-0 pl-5">
                <h3 className="card-title font-weight-bolder text-dark">
                  Notification
                </h3>
                <div className="card-toolbar">
                  <div class="dropdown dropdown-inline">
                    {/* <a href="#" class="btn btn-light bg-white btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Latest</a> */}
                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      <ul class="navi navi-hover">
                        <li class="navi-header pb-1">
                          <span class="text-primary text-uppercase font-weight-bold font-size-sm">
                            Add new:
                          </span>
                        </li>
                        <li class="navi-item">
                          <a href="#" class="navi-link">
                            <span class="navi-icon">
                              <i class="flaticon2-writing"></i>
                            </span>
                            <span class="navi-text">File</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0 mob_view_h max-h-500px pr-5 overflow-auto pl-5">
                {/* <div className="d-flex align-items-center flex-wrap mb-4 m-0">
                <div className="symbol symbol-50 mr-5 symbol-light-success">
                  <span className="symbol-label">
                    <img src="/media/users/300_20.jpg" className="w-100 align-self-center rounded" alt="" />
                  </span>
                </div>
                <div className="d-flex flex-column flex-grow-1 mr-0">
                  <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <a href="#" className="font-weight-bolder text-dark-75 text-hover-primary font-size-lg mb-1 text-truncate  max-w-150px">New Application</a>
                    <span className="text-muted font-size-sm">5 Mins</span>
                  </div>
                  <span className="text-muted font-weight-bold text-truncate max-w-200px">You have a new application request received</span>
                </div>
              </div> */}
                {list === true && (
                  <div className="d-flex justify-content-center align-item-center my-10 mx-auto">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                {notificationsList.length
                  ? notificationsList.map((item, index) => (
                      <div
                        className="d-flex align-items-center flex-nowrap mb-4 m-0"
                        key={index}
                      >
                        <div className="symbol symbol-50 mr-5 symbol-light-success">
                          <span
                            className="symbol-label"
                            style={{
                              backgroundImage: item.profileImage
                                ? "url(" +
                                  `${process.env.REACT_APP_AWS_S3_BASE_URL +
                                    process.env
                                      .REACT_APP_S3_USER_PROFILE_FOLDER +
                                    "/" +
                                    item.profileImage}` +
                                  ")"
                                : "url(/media/dummyImages/no_preview.png)",
                            }}
                          >
                            {/* <img src="/media/users/300_20.jpg" className="w-100 align-self-center rounded" alt="" /> */}
                          </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 mr-0">
                          <div className="d-flex align-items-center flex-nowrap justify-content-between">
                            <a
                              href="#"
                              style={{ width: "60%" }}
                              className="font-weight-bolder text-dark-75 text-hover-primary notification_text font-size-lg mb-1 max-w-160px  text-truncate"
                              title={item.notfyHead}
                              onClick={() =>
                                item?.path ? history.push(item?.path) : "#"
                              }
                            >
                              {item.notfyHead}
                            </a>
                            <span className="text-muted font-size-sm max-w-100px">
                              {moment(item.date)
                                .startOf()
                                .fromNow() !== "Invalid date"
                                ? moment(item.date)
                                    .startOf()
                                    .fromNow()
                                : null}
                            </span>
                          </div>
                          <span
                            className="text-muted message_text text-truncate"
                            title={item.message}
                          >
                            {item.message}
                          </span>
                        </div>
                      </div>
                    ))
                  : null}
                {notificationsList?.length < 1 && (
                  <div class="card-body pt-0 pb-0 px-0">
                    <div class="row no_data_campaign text-center mx-0">
                      <h6 class="mx-auto font-weight-bold px-5 w-100 mb-0 mt-6 text-dark-50 d-none">
                        No Active campaigns yet
                      </h6>
                      {/* <div class="w-100 mt-5">
                        <a class="text-primary  cursor-pointer">Create new</a>
                      </div> */}
                      <img
                        class=" mr-0 w-100 gray_scale"
                        src="/media/custom/no_notification.svg"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/*Notification END*/}
          {/*message*/}
          {component === "messageInbox" && (
            <MessageInbox chatInboxArray={chatInboxArray} />
          )}
          {/*message END*/}
          {component === "campaigns" && <SideBarCampaigns />}
        </div>
      </div>
    </>
  );
}
