import React from "react";
import { parseISO } from "date-fns";
import { format } from "date-fns";

export function DateFormatter(cellContent) {
  let formattedDate = "";
  if (cellContent) {
    let date = parseISO(cellContent);
    formattedDate = format(date, "MMM do, yyyy p").toString();
  }
  return <span>{formattedDate}</span>;
}