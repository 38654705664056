import React, { Suspense } from "react";
// import { Route, Switch } from "react-router-dom";
import { Switch,Redirect } from "react-router-dom";
// import { PostAnalyticsCard } from "./pages/PostAnalyticsCard";
// import { ProductsPage } from "./products/ProductsPage";
// import { ProductEdit } from "./products/product-edit/ProductEdit";
// import { Index } from "./index"
import { LayoutSplashScreen , ContentRoute } from "../../../_metronic/layout"
import {DashboardView} from "./DashboardView";


// export default function DashboardPage() {
//   return (
//         <Suspense  fallback={<LayoutSplashScreen />}>
//           <Switch>
//           {/ <Route path="/dashboard" component={DashboardView} exact={true} /> /}
//           <Redirect exact={true} from="/dashboard" to="/dashboard/view" />
//         <ContentRoute from="/dashboard/view" component={DashboardView} />
//         </Switch>
//           {/ </Switch> /}
//         </Suspense>
//   )
// }
export default function DashboardPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* {/  Redirect from eCommerce root URL to /customers /} */}
        <Redirect exact={true} from="/dashboard" to="/dashboard/view" />
        <ContentRoute from="/dashboard/view" component={DashboardView} />
      </Switch>
    </Suspense>
  );
}