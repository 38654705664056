import React from "react";
import { useHistory } from "react-router-dom";

function NavTab({current}) {
 const history = useHistory()
  return (

      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <button
              class={current == "active" ? "btn btn-sm btn-primary" : "btn btn-sm"}
              onClick={() => history.push(`/activeCampaign`)}
            >
              Active
            </button>
          </li>
          <li class="nav-item">
            <button
              class={current == "pending" ? "btn btn-sm btn-primary" : "btn btn-sm"}
              onClick={() => history.push(`/pendingCampaign`)}
            >
              Pending
            </button>
          </li>
          <li class="nav-item">
            <button
              class={current == "completed" ? "btn btn-sm btn-primary" : "btn btn-sm"}
              onClick={() => history.push(`/completedCampaign`)}
            >
              Completed
            </button>
          </li>
        </ul>
      </div>

  );
}

export default NavTab;
