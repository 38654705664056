import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { verifyBrand } from "../_redux/authCrud";
import { Toaster } from "../../../components/Toaster";

const initialValues = {
  username: "",
  code: "",
};

function Invite(props) {
    const history = useHistory();
    const { intl } = props;
    const {inviteCode,inviteId}=useParams();
    const [loading, setLoading] = useState(false);
    const InviteSchema = Yup.object().shape({
        username: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        code: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

  // const getInputClasses = (fieldname) => {
    //     if (formik.touched[fieldname] && formik.errors[fieldname]) {
    //         return "is-invalid";
    //     }

    //     if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //         return "is-valid";
    //     }

    //     return "";
    // };
    useEffect(()=>{
        verifyBrand({ username: inviteId, code: inviteCode })
                    .then(({ data }) => {
                        // disableLoading();
                        if (data.success === true) {
                            props.history.push("/auth/registration",
                            {verifiedData:data}
                            );
                        }
                        
                    })
                    .catch((err) => {
                        console.log(err);
                        Toaster({ message: err?.response?.data?.data?.message.replaceAll("_", ' '), variant: "dark" });
                        
                        // history.push("/error");
                        // disableLoading();
                        // props.login("access-token-8f3ae836da744329a6f93bf20594b5cc");
                    });
    },[])

    // const formik = useFormik({
    //     initialValues,
    //     validationSchema: InviteSchema,
    //     onSubmit: (values, { setStatus, setSubmitting }) => {
    //         enableLoading();
    //         setTimeout(() => {
    //             verifyBrand({ username: values.username, code: values.code })
    //                 .then(({ data }) => {
    //                     disableLoading();
    //                     if (data.success === true) {
    //                         props.history.push("/auth/registration",
    //                         {verifiedData:data}
    //                         );
    //                     }
    //                     // props.login(data);

    //                 })
    //                 .catch(() => {
    //                     disableLoading();
    //                     // props.login("access-token-8f3ae836da744329a6f93bf20594b5cc");
    //                 });
    //         }, 1000);
    //     },
    // });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            {/* <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.REGISTER.TITLE" />
                </h3>
                <br/>
                <p className="text-muted font-weight-bold">
                    Enter the mobile number / email id and the code you had invited to the platform
                </p>
            </div> */}
            {/* end::Head */}

            {/*begin::Form*/}
            {/* <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email/Mobile Number"
                        type="username"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "username"
                        )}`}
                        name="username"
                        {...formik.getFieldProps("username")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Code"
                        type="code"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "code"
                        )}`}
                        name="code"
                        {...formik.getFieldProps("code")}
                    />
                    {formik.touched.code && formik.errors.code ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Continue</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                </div>
            </form> */}
            {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Invite));
