import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { move, useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import * as actions from "../../_redux/commonActions";
import {
  login,
  googleSignInorLogin,
  generateOtpLogin,
  loginOrSignUp,
  getTokenDataForWeb,
} from "../_redux/authCrud";
import PhoneInput from "react-phone-input-2";
import ComponentLoader from "../../../components/ComponentLoader";
import { Toaster } from "../../../components/Toaster";
import { FormatColorReset } from "@material-ui/icons";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "./index.scss";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: undefined,
  password: "",
  phoneNumber: "",
};

function Login(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [credential, setCredential] = useState();
  const [isOtp, setIsOtp] = useState(false);
  const [dataToSend, setDataToSend] = useState({});
  const [seconds, setSeconds] = useState(0);
  const [error, setError] = useState("");
  const [resendPhoneCode, setResendPhoneCode] = useState("");
  const [resendPhoneNumber, setResendPhoneNumber] = useState("");
  const [resendEmail, setResendEmail] = useState("");
  const [disableResendOtp, setDisableResendOtp] = useState(false);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  // const userId = props.history.location.state?.verifiedData?.data[0].userId;
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [phoneStatus, setPhoneStatus] = useState(true);
  const phoneSchema = Yup.object().shape({
    phoneNumber: Yup.string("Should be a number").required(
      "Mobile number is required"
    ),
    // password: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
  });
  const emailSchema = Yup.object().shape({
    // email: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),

    // password: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const verifyOtp = (paramsForLogin) => {
    enableLoading();
    loginOrSignUp(paramsForLogin)
      .then(({ data }) => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        if (!data?.data.companyName) {
          localStorage.setItem("enterBrandName", true);
        } else {
          localStorage.setItem("enterBrandName", false);
        }
        if (data?.data?.userType == "NEW_USER") {
          localStorage.setItem("openModal", true);
        }
        props.login(data?.data);
        disableLoading();
        // setSubmitting(false);
      })
      .catch((err) => {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        disableLoading();
        // setSubmitting(false);
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    if (id) {
      dispatch(actions.startGetTokenDataLoader());
      getTokenDataForWeb({ id })
        .then((response) => {
          dispatch(actions.endGetTokenDataLoader());
          if (!response?.data?.data?.companyName) {
            localStorage.setItem("enterBrandName", true);
          } else {
            localStorage.setItem("enterBrandName", false);
          }
          if (response?.data?.data.userType == "NEW_USER") {
            localStorage.setItem("openModal", true);
          }
          props.login(response?.data?.data);
        })
        .catch((err) => {
          alert(`Invalid URL, ${err.response.data.data.message}.`);
          dispatch(actions.endGetTokenDataLoader());
        });
    }
  }, [id]);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("time exceed!");
    }
  }, [seconds]);

  //------------------------------- Google Signin Setup ---------------------------

  useEffect(() => {
    const googleSignInScript = document.createElement("script");
    googleSignInScript.src = "https://accounts.google.com/gsi/client";
    googleSignInScript.async = true;
    window.document.body.appendChild(googleSignInScript);
  }, [isOtpSend]);
  useEffect(() => {
    credential && enableLoading();
    credential &&
      googleSignInorLogin({ token: credential }).then(({ data }) => {
        if (data.data.status === "Login") {
          props.login(data?.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential]);

  // ----------------------------------End of Google Signin Setup- ----------------------------

  const move = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");
    if (length == maxLength) {
      if (n !== "") {
        document.getElementById(n).focus();
      }
    }
    if (e.key === "Backspace") {
      if (p !== "") {
        document.getElementById(p).focus();
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: phoneStatus ? phoneSchema : emailSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setResendPhoneCode(values.phoneCode);
      setResendPhoneNumber(values.phoneNumber);
      setResendEmail(values.email);
      enableLoading();
      generateOtpLogin(values)
        .then(({ data }) => {
          // localStorage.setItem("openModal",true);
          resetForm({});
          setIsOtpSend(true);
          Toaster({ message: "OTP Sent", variant: "dark" });
          setIsOtp(true);
          setSeconds(30);
          setStatus(false);
          setDataToSend({
            phoneNumber: values.phoneNumber,
            phoneCode: values.phoneCode,
            email: values?.email,
          });
          // props.login(data?.data);
          disableLoading();
          // setSubmitting(false);
        })
        .catch((err) => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  let minutesTobeShown = Math.floor(seconds / 60);
  let secondsToBeShown = seconds - minutesTobeShown * 60;

  return (
    <div
      className="login-form login-signin mb-3 w-100"
      id="kt_login_signin_form"
    >
      {/* {loading && <div><ComponentLoader /></div>} */}
      {/* begin::Head */}
      <div className=" mb-lg-0 mt-0 ">
        {!isOtpSend ? (
          <h3 className="font-size-h3 text-center mb-8">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
        ) : (
          <h3 className="font-size-h3 mb-8">
            <span
              className="mr-2 cursor-pointer"
              onClick={() => {
                setIsOtpSend(false);
                setIsOtp(false);
                setPhoneStatus(true);
              }}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-left.svg"
                )}
                className="h-150 align-self-center"
              ></SVG>
            </span>
            <FormattedMessage id="AUTH.LOGIN.OTP" />
          </h3>
        )}
        {/* <p className="text-muted font-weight-bold">
          Enter your Username and password
        </p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}

      {!isOtp && (
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            ""
          )}
          {/* <div className="form-group fv-plugins-icon-container">
          <input
            type="radio"
            name="loginType"
            value="phoneVia"
            onChange={() => {
              formik.setFieldValue("email", undefined);
              setPhoneStatus(true);
            }}
            defaultChecked={true}
          />
          <label className="ml-2" htmlFor="phoneWise">
            Mobile Number
          </label>
          <input
            className="ml-5"
            type="radio"
            name="loginType"
            value="emailVia"
            onChange={() => {
              formik.setFieldValue("phoneNumber", undefined);
              setPhoneStatus(false);
            }}
          />
          <label className="ml-2" htmlFor="emailVia">
            Email
          </label>
        </div> */}
          {phoneStatus ? (
            <div className="form-group fv-plugins-icon-container">
              <PhoneInput
                enableAreaCodes={true}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                  autoComplete: "off",
                }}
                // value={
                //   formik.values["phoneCode"]
                //     ? formik.values["phoneCode"] + formik.values["phoneNumber"]
                //     : null
                // }
                placeholder="Enter phone number"
                country={"in"}
                preferredCountries={["ae", "sa"]}
                countryCodeEditable={false}
                enableSearch={true}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                }}
                onChange={(value, countryData) => {
                  let phoneCode = `+${countryData.dialCode}`;
                  let phoneNumber = value.replace(countryData.dialCode, "");
                  formik.setFieldValue("phoneNumber", phoneNumber);
                  formik.setFieldValue("phoneCode", phoneCode);
                }}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.phoneNumber}
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control ${getInputClasses("email")}`}
                name="email"
                {...formik.getFieldProps("email")}
                value={formik.values.email || ""}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          )}

          {/* <div className="form-group fv-plugins-icon-container mb-4">
          <input
            placeholder="Password"
            type="password"
            className={`form-control ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div> */}
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
            {/* <Link
            // to="/auth/forgot-password"
            to="#"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
            {/* <Link
            to="/auth/forgot-password"
            className="font-weight-bold ml-0 font-size-sm col-md-12 pl-0 mb-3"
            id="kt_auth_login_forgot_password"
          >
            Forgot Password ?
          </Link> */}
            <div className="col-md-12 text-center px-0">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn_secondary h-45px w-100`}
              >
                {!loading ? (
                  <span>
                    <b>Continue</b>
                  </span>
                ) : (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </form>
      )}

      {isOtp && (
        <div className="fv-plugins-icon-container">
          {error.length > 1 ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {"You have entered wrong otp"}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <input
            placeholder="Enter otp"
            type="text"
            className={'form-control '}
            name="otp"
            onChange={(e) => {
              setOtp(e.target.value)
              setError('')
            }}
          /> */}

          <div className="d-flex justify-content-between mx-6 mt-8 form-group">
            <input
              name="otp1"
              autoComplete="off"
              className="otpInput"
              id="otp1"
              inputMode="numeric"
              type="text"
              // value={this.state.otp1}
              // onKeyPress={this.keyPressed}
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp1("")
                  : setOtp1(e.target.value)
              }
              tabIndex="1"
              maxLength={1}
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "", "otp1", "otp2")}
              value={otp1}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              inputMode="numeric"
              className="otpInput"
              id="otp2"
              // value={this.state.otp2}
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp2("")
                  : setOtp2(e.target.value)
              }
              tabIndex="2"
              maxLength={1}
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp1", "otp2", "otp3")}
              value={otp2}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              inputMode="numeric"
              className="otpInput"
              // value={this.state.otp3}
              id="otp3"
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp3("")
                  : setOtp3(e.target.value)
              }
              tabIndex="3"
              maxLength={1}
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp2", "otp3", "otp4")}
              value={otp3}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              inputMode="numeric"
              className="otpInput"
              // value={this.state.otp4}
              id="otp4"
              onChange={(e) =>
                /[^0-9]/g.test(e.target.value)
                  ? setOtp4("")
                  : setOtp4(e.target.value)
              }
              tabIndex="4"
              maxLength={1}
              autocomplete="one-time-code"
              onKeyUp={(e) => move(e, "otp3", "otp4", "")}
              value={otp4}
            />
          </div>
          <div className="fv-plugins-message-container">
            {phoneStatus ? (
              <div className="fv-help-block-info">
                We sent a 4 digit code to{" "}
                <b>{resendPhoneCode + "-" + resendPhoneNumber} </b>
                via SMS to verify your number
              </div>
            ) : (
              <div className="fv-help-block-info">
                We've sent a verification code to your email -{" "}
                <b>{resendEmail}</b>
              </div>
            )}
          </div>
          <div className="mb-3 mt-5 d-flex flex-wrap align-items-center justify-content-between">
            <div className="col-md-12 text-center px-0">
              <button
                id="kt_login_signin_submit"
                // type="submit"
                // disabled={formik.isSubmitting}
                disabled={
                  loading ||
                  otp1.length < 1 ||
                  otp2.length < 1 ||
                  otp3.length < 1 ||
                  otp4.length < 1
                }
                className={`btn btn_secondary h-45px w-100`}
                onClick={() => {
                  let objToSend = {
                    loginCode: otp1 + otp2 + otp3 + otp4,
                    ...dataToSend,
                  };
                  verifyOtp(objToSend);
                }}
              >
                {<span>Verify</span>}
                {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
          {seconds >= 1 && (
            <a
              // to="https://1000x.club/legal.html"
              target="_blank"
              className={` d-flex justify-content-center font-weight-bold ml-0 font-size-sm cursor-pointer`}
              rel="noopener noreferrer"
              // href="#"
            >
              Resend otp in {"0" + minutesTobeShown + " : " + secondsToBeShown}
            </a>
          )}
          {typeof seconds === "string" ? (
            <a
              // to="https://1000x.club/legal.html"
              target="_blank"
              className={` d-flex justify-content-center font-weight-bold ml-0 font-size-sm cursor-pointer ${disableResendOtp &&
                "text-muted"}`}
              rel="noopener noreferrer"
              // href="#"
              onClick={() => {
                setSeconds(30);
                setDisableResendOtp(true);
                generateOtpLogin({
                  phoneNumber: resendPhoneNumber,
                  phoneCode: resendPhoneCode,
                  email: resendEmail,
                }).then(() => {
                  Toaster({ message: "OTP Sent", variant: "dark" });
                  setDisableResendOtp(false);
                });
              }}
            >
              Click here to resend OTP
            </a>
          ) : null}
        </div>
      )}

      {/*end::Form*/}

      {/* -----------------Google Signin Call back function--------------- */}

      {
        (window.loginCallBackFn = (resp, resp1) => {
          setCredential(resp.credential);
        })
      }
      {/* -----------------End of Google Signin Call back function--------------- */}

      {/* ----------------Google Siginin Button */}

      {!isOtpSend && (
        <p className="d-flex justify-content-center text-muted m-1">Or</p>
      )}
      {!isOtpSend && (
        <div className="form-group row justify-content-center">
          <div className="d-flex justify-content-center mr-3">
            <div
              id="g_id_onload"
              data-client_id={process.env.REACT_APP_GOOGLE_SIGN_UP_CLIENT_ID}
              data-context="signin"
              data-ux_mode="popup"
              data-callback="loginCallBackFn"
              data-auto_prompt="false"
            ></div>

            <div
              class="g_id_signin w-auto mx-auto"
              data-type="icon"
              data-shape="rectangular"
              data-theme="outline"
              data-text="continue_with"
              data-size="large"
              data-logo_alignment="left"
            ></div>
          </div>

          <div
            className="cursor-pointer"
            onClick={() => {
              setPhoneStatus(!phoneStatus);
            }}
          >
            {phoneStatus ? (
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Frame-1-4.svg")}
                className="h-150 align-self-center"
              ></SVG>
            ) : (
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Frame-1-5.svg")}
                className="h-150 align-self-center"
              ></SVG>
            )}
          </div>
        </div>
      )}
      {/*---------------- End of Google  Siginin Button*/}
      <p class="text-small" id="signup_disclosure">
        By creating an account, you agree to our{" "}
        <a
          href="https://1000x.club/terms_and_condition.html"
          class="text-fast-blue"
          target="_blank"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://1000x.club/privacy_policy.html"
          class="text-fast-blue"
          target="_blank"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
