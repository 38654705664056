import React, { useState, useEffect } from "react";
import { Col, Image, Row, Card, Button, Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useSubheader } from "../../_metronic/layout";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { body2, body1, body3 } from "./helper/ModalBody";
import { getCreatorProfile } from "../modules/_redux/commonActions";
import { viewModalInfo } from "../modules/_redux/commonActions";
import * as actions from "../modules/_redux/commonActions";

export const ChooseApplicationType = () => {
  const suhbeader = useSubheader();
  const [screen, setScreen] = useState(1);
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [inputCompanyName, setInputCompanyName] = useState("");
  const dispatch = useDispatch();
  // const [count,setCount] useState(0);
  const history = useHistory();
  suhbeader.setTitle("My Custom title");
  const switchRoute = (path) => history.push(path);
  const { user, email, isVisibleModalInfo, profileToEdit } = useSelector(
    (state) => ({
      user: state.auth.user,
      email: state?.common?.profileToEdit?.loginEmail,
      isVisibleModalInfo: state.common.isVisibleModalInfo,
      profileToEdit: state.common.profileToEdit,
    }),
    shallowEqual
  );

  const getmodalScreen = (screenNumber) => {
    switch (screenNumber) {
      case 1:
        return body1;
      case 2:
        return body2;
      case 3:
        return body3;
      default:
        return null;
    }
  };
  const isModalOpen = localStorage.getItem("openModal");
  const isBrandName = localStorage.getItem("enterBrandName");
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (isModalOpen === "true" && isBrandName === "false") {
      screen <= 3 &&
        Swal.fire({
          type: "info",
          html: getmodalScreen(screen),
          // text:body1,
          // content: errors,
          showCloseButton: true,
          // showCancelButton: screen === 1 ? false :true,
          confirmButtonText: screen === 3 ? "Close" : "Next",
          confirmButtonColor: "#3699FF",
          // cancelButtonColor: "#EBEBED",
          confirmButtonClass: "btn_primary",
          // cancelButtonClass: "btn_secondary",
          cancelButtonText: "Back",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: true,
          allowOutsideClick: false,
          reversebuttonsText: "Back",
          showDenyButton: screen === 1 ? false : true,
          denyButtonColor: "#EBEBED",
          denyButtonClass: "btn_secondary",
          denyButtonText: "Back",
        }).then((resp) => {
          resp.isConfirmed && setScreen(screen + 1);
          resp.isDenied && setScreen(screen - 1);
          resp.isDismissed && localStorage.setItem("openModal", false);
          screen > 3 && localStorage.setItem("openModal", false);
        });
    }
  }, [isVisibleModalInfo, isModalOpen, dispatch, screen, isBrandName]);

  useEffect(() => {
    if (isBrandName === "true" || profileToEdit?.companyName === "") {
      // setShow(true);
    }
  }, [isBrandName, profileToEdit]);

  const submitBrandName = () => {
    setloading(true);
    dispatch(
      actions.updateCreatorProfile({
        companyName: inputCompanyName,
        loggedInUserId: user.userId,
        userId: user.userId,
      })
    ).then(() => {
      dispatch(
        actions.getCreatorProfile({
          loggedInUserId: user.userId,
          userId: user.userId,
        })
      );
      setShow(false);
      setloading(false);
      localStorage.setItem("enterBrandName", false);
    });
  };

  useEffect(() => {
    dispatch(
      getCreatorProfile({
        userId: user?.userId,
        loggedInUserId: user?.userId,
        fields: [
          "profilePic",
          "firstName",
          "lastName",
          "companyName",
          "loginEmail",
          "phoneNumber",
          "gender",
          "age",
          "creatorType",
          "companyName",
        ],
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCompanyName = () => {
    Swal.fire({
      title: "Oops",
      text: "Please enter your Brand Name",
      icon: "warning",
      allowOutsideClick: false,
      // showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/user-profile/personal-information");
      }
    });
  };

  return (
    <>
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xl-9">
              <div class="card card-custom bg_gradient bgi-no-repeat overflow-hidden gutter-b p-0 mb-8">
                <div class="card-body d-flex align-items-start p-0">
                  <div>
                    <Card.Img
                      src="/media/bg/cover_bg.png"
                      alt="Social Media Campaign"
                    ></Card.Img>
                    <Card.ImgOverlay className="card-img-overlay d-flex align-items-end">
                      <h3 class="text-white font-weight-bolder line-height-lg mb-5 pl-4">
                        Get started!
                        <p class="text-white font-size-sm font-weight-normal max-w-200px mb-0">
                          Your brand story gets created by our top creators and
                          influencers, maximizing your marketing results.
                        </p>
                      </h3>
                    </Card.ImgOverlay>
                    {email === "" ? (
                      <div className="mb-3">
                        <span
                          className="card bg-info text-white w-100 p-2"
                          style={{ wordBreak: "break-all", maxWidth: "700px" }}
                        >
                          Please update your primary email so that we will
                          notify you whenever you get new updates.
                          <br></br>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div class="card-body pt-0 px-5">
                  <div class="d-column row">
                    <div class="col-12 ">
                      <div class="d_card w-100 d-flex align-content-between flex-wrap cursor-pointer">
                        <div class="col-12 px-4 d-flex justify-content-end">
                          <span class="label label-outline-success label-pill label-inline label_dark">
                            FINANCE
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <p
                            class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                            title="Create Contents"
                          >
                            Relationship building
                          </p>
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                  <span
                                    class="symbol-label"
                                    style={{
                                      backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/stock-600x600/img-8.jpg"
                                      )})`,
                                    }}
                                  ></span>
                                </div>
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  <a
                                    href="#"
                                    class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                                  >
                                    AnjuBB
                                  </a>
                                  <span class="text-light font-size-xs">
                                    <img
                                      class="mr-1 h-10px mt-n1"
                                      src="/media/svg/icons/custom/Icon-calendar.svg"
                                    />{" "}
                                    <span class="opacity-50">9 Jul 2022</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <span class="number text-theme font-weight-Bold font-size-h3">
                                <img
                                  class="mt-n1 mr-1"
                                  src="/media/custom/Icon_rupee_sign.svg"
                                />
                                2,500
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="d_card w-100 d-flex align-content-between flex-wrap cursor-pointer">
                        <div class="col-12 px-4 d-flex justify-content-end">
                          <span class="label label-outline-success label-pill label-inline label_dark">
                            FINANCE
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <p
                            class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                            title="Create Contents"
                          >
                            Relationship building
                          </p>
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                  <span
                                    class="symbol-label"
                                    style={{
                                      backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/stock-600x600/img-8.jpg"
                                      )})`,
                                    }}
                                  ></span>
                                </div>
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  <a
                                    href="#"
                                    class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                                  >
                                    AnjuBB
                                  </a>
                                  <span class="text-light font-size-xs">
                                    <img
                                      class="mr-1 h-10px mt-n1"
                                      src="/media/svg/icons/custom/Icon-calendar.svg"
                                    />{" "}
                                    <span class="opacity-50">9 Jul 2022</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <span class="number text-theme font-weight-Bold font-size-h3">
                                <img
                                  class="mt-n1 mr-1"
                                  src="/media/custom/Icon_rupee_sign.svg"
                                />
                                2,500
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="d_card w-100 d-flex align-content-between flex-wrap cursor-pointer">
                        <div class="col-12 px-4 d-flex justify-content-end">
                          <span class="label label-outline-success label-pill label-inline label_dark">
                            FINANCE
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <p
                            class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                            title="Create Contents"
                          >
                            Relationship building
                          </p>
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                  <span
                                    class="symbol-label"
                                    style={{
                                      backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/stock-600x600/img-8.jpg"
                                      )})`,
                                    }}
                                  ></span>
                                </div>
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  <a
                                    href="#"
                                    class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                                  >
                                    AnjuBB
                                  </a>
                                  <span class="text-light font-size-xs">
                                    <img
                                      class="mr-1 h-10px mt-n1"
                                      src="/media/svg/icons/custom/Icon-calendar.svg"
                                    />{" "}
                                    <span class="opacity-50">9 Jul 2022</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <span class="number text-theme font-weight-Bold font-size-h3">
                                <img
                                  class="mt-n1 mr-1"
                                  src="/media/custom/Icon_rupee_sign.svg"
                                />
                                2,500
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="d_card w-100 d-flex align-content-between flex-wrap cursor-pointer">
                        <div class="col-12 px-4 d-flex justify-content-end">
                          <span class="label label-outline-success label-pill label-inline label_dark">
                            FINANCE
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <p
                            class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                            title="Create Contents"
                          >
                            Relationship building
                          </p>
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                  <span
                                    class="symbol-label"
                                    style={{
                                      backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/stock-600x600/img-8.jpg"
                                      )})`,
                                    }}
                                  ></span>
                                </div>
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  <a
                                    href="#"
                                    class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                                  >
                                    AnjuBB
                                  </a>
                                  <span class="text-light font-size-xs">
                                    <img
                                      class="mr-1 h-10px mt-n1"
                                      src="/media/svg/icons/custom/Icon-calendar.svg"
                                    />{" "}
                                    <span class="opacity-50">9 Jul 2022</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <span class="number text-theme font-weight-Bold font-size-h3">
                                <img
                                  class="mt-n1 mr-1"
                                  src="/media/custom/Icon_rupee_sign.svg"
                                />
                                2,500
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="d_card w-100 mb-0 d-flex align-content-between flex-wrap cursor-pointer">
                        <div class="col-12 px-4 d-flex justify-content-end">
                          <span class="label label-outline-success label-pill label-inline label_dark">
                            FINANCE
                          </span>
                        </div>
                        <div class="col-12 px-4">
                          <p
                            class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                            title="Create Contents"
                          >
                            Relationship building
                          </p>
                          <div class="d-flex d-flex align-items-center">
                            <div class="flex-grow-1">
                              <div class="d-flex align-items-center mb-0">
                                <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                                  <span
                                    class="symbol-label"
                                    style={{
                                      backgroundImage: `url(${toAbsoluteUrl(
                                        "/media/stock-600x600/img-8.jpg"
                                      )})`,
                                    }}
                                  ></span>
                                </div>
                                <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                                  <a
                                    href="#"
                                    class="text-light text-hover-primary mb-0 font-size-sm text-truncate"
                                  >
                                    AnjuBB
                                  </a>
                                  <span class="text-light font-size-xs">
                                    <img
                                      class="mr-1 h-10px mt-n1"
                                      src="/media/svg/icons/custom/Icon-calendar.svg"
                                    />{" "}
                                    <span class="opacity-50">9 Jul 2022</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="flex-shrink-0">
                              <span class="number text-theme font-weight-Bold font-size-h3">
                                <img
                                  class="mt-n1 mr-1"
                                  src="/media/custom/Icon_rupee_sign.svg"
                                />
                                2,500
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card card-custom  gutter-b">
                <div class="card-header border-0 pt-5">
                  <div class="card-title font-weight-bolder">
                    <div class="card-label">Applications List</div>
                  </div>
                </div>
                <div class="card-body pt-0 pb-0">
                  <div class="table-responsive">
                    <table class="table campaign_details table-borderless table-vertical-center">
                      <thead>
                        <tr>
                          <th class="p-0 w-50px"></th>
                          <th class="p-0"></th>
                          <th class="p-0 min-w-225px"></th>
                          <th class="p-0 min-w-85px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-1.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-17.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-warning label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-info label-pill label-inline mr-2">
                              Waiting
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-danger label-pill label-inline">
                              Rejected
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-2.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-16.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-3.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-15.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-4.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-14.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-6.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-13.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-7.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-12.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-8.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-10.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2 pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2">
                              <span
                                class="symbol-label"
                                style={{
                                  backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/stock-600x600/img-11.jpg"
                                  )})`,
                                }}
                              ></span>
                            </div>
                          </td>
                          <td class="pl-0 pr-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize"
                            >
                              What are you looking for?
                            </a>
                            <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                              <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                                <span
                                  class="symbol-label"
                                  style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-9.jpg"
                                    )})`,
                                  }}
                                ></span>
                              </div>
                              <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                                AnjuBB
                              </span>
                              <span class="pr-2">
                                <span>20 May 2022</span>
                              </span>
                            </span>
                          </td>
                          <td class="text-right px-0">
                            <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
                              Paid
                            </span>
                            <span class="label label-sm font-weight-Medium label-outline-primary label-pill label-inline mr-2">
                              Submitted
                            </span>
                            <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
                              Approved
                            </span>
                          </td>
                          <td class="text-right pr-0">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-sm mr-2"
                            >
                              <span class="svg-icon-1x">
                                <svg
                                  width="17.37"
                                  height="17.37"
                                  viewBox="0 0 17.37 17.37"
                                >
                                  <path
                                    d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                                    transform="translate(-3.75 -3.75)"
                                    fill="rgba(0,0,0,0)"
                                    stroke="#000"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></path>
                                </svg>
                              </span>
                            </a>
                            <a href="#" class="btn btn-icon btn-light btn-sm">
                              <span class="svg-icon-1x">
                                <i class="ki ki-bold-more-ver"></i>
                              </span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-12 px-0 mb-5 d-flex justify-content-between">
                <h4 class="font-weight-SemiBold">Gallery</h4>
                <a class="font-weight-Medium text-dark" href="#">
                  More
                </a>
              </div>
              <div class="row w-100 mb-6 d-flex flex-nowrap div_overflow overflow-auto mx-0">
                <div class="col pr-3 pl-0">
                  <div class="galler_card card card-custom mb-6">
                    <div
                      class="d_card no_hover w-100 d-flex align-content-between flex-wrap cursor-pointer"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "/media/stock-900x600/23.jpg"
                        )})`,
                      }}
                    >
                      <div class="col-12 px-4 d-flex justify-content-end">
                        <a
                          href="#"
                          class="btn btn-icon bg-light-o-90 btn-sm h-25px w-25px mr-0"
                        >
                          <img
                            class="h-15px"
                            src="/media/svg/icons/custom/Icon_download.svg"
                          />
                        </a>
                      </div>
                      <div class="col-12 px-4">
                        <p
                          class="w-100 mb-1 text-light font-size-xs text-truncate font-weight-SemiBold"
                          title="Create Contents"
                        >
                          Relationship building
                        </p>
                      </div>
                    </div>
                    <div class="d-flex d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-center mb-0 p-3">
                          <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                            <span
                              class="symbol-label"
                              style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                  "/media/stock-600x600/img-9.jpg"
                                )})`,
                              }}
                            ></span>
                          </div>
                          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                            <a
                              href="#"
                              class="text-dark font-weight-SemiBold text-hover-primary mb-0 font-size-xs text-capitalize"
                            >
                              AnjuBB
                            </a>
                            <span class="text-dark font-size-xs font-weight-Medium">
                              <span class="opacity-50">9 Jul 2022</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col px-3">
                  <div class="galler_card card card-custom mb-6">
                    <div
                      class="d_card no_hover w-100 d-flex align-content-between flex-wrap cursor-pointer"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "/media/stock-900x600/7.jpg"
                        )})`,
                      }}
                    >
                      <div class="col-12 px-4 d-flex justify-content-end">
                        <a
                          href="#"
                          class="btn btn-icon bg-light-o-90 btn-sm h-25px w-25px mr-0"
                        >
                          <img
                            class="h-15px"
                            src="/media/svg/icons/custom/Icon_download.svg"
                          />
                        </a>
                      </div>
                      <div class="col-12 px-4">
                        <p
                          class="w-100 mb-1 text-light font-size-xs text-truncate font-weight-SemiBold"
                          title="Create Contents"
                        >
                          Anju's third campaign
                        </p>
                      </div>
                    </div>
                    <div class="d-flex d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-center mb-0 p-3">
                          <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                            <span
                              class="symbol-label"
                              style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                  "/media/stock-600x600/img-11.jpg"
                                )})`,
                              }}
                            ></span>
                          </div>
                          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                            <a
                              href="#"
                              class="text-dark font-weight-SemiBold text-hover-primary mb-0 font-size-xs text-capitalize"
                            >
                              AnjuBB
                            </a>
                            <span class="text-dark font-size-xs font-weight-Medium">
                              <span class="opacity-50">9 Jul 2022</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col px-3">
                  <div class="galler_card card card-custom mb-6">
                    <div
                      class="d_card w-100 no_hover d-flex align-content-between flex-wrap cursor-pointer"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "/media/stock-900x600/8.jpg"
                        )})`,
                      }}
                    >
                      <div class="col-12 px-4 d-flex justify-content-end">
                        <a
                          href="#"
                          class="btn btn-icon bg-light-o-90 btn-sm h-25px w-25px mr-0"
                        >
                          <img
                            class="h-15px"
                            src="/media/svg/icons/custom/Icon_download.svg"
                          />
                        </a>
                      </div>
                      <div class="col-12 px-4">
                        <p
                          class="w-100 mb-1 text-light font-size-xs text-truncate font-weight-SemiBold"
                          title="Create Contents"
                        >
                          Photo needed
                        </p>
                      </div>
                    </div>
                    <div class="d-flex d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-center mb-0 p-3">
                          <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                            <span
                              class="symbol-label"
                              style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                  "/media/stock-600x600/img-13.jpg"
                                )})`,
                              }}
                            ></span>
                          </div>
                          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                            <a
                              href="#"
                              class="text-dark font-weight-SemiBold text-hover-primary mb-0 font-size-xs text-capitalize"
                            >
                              AnjuBB
                            </a>
                            <span class="text-dark font-size-xs font-weight-Medium">
                              <span class="opacity-50">9 Jul 2022</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col px-3">
                  <div class="galler_card card card-custom mb-6">
                    <div
                      class="d_card no_hover w-100 d-flex align-content-between flex-wrap cursor-pointer"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "/media/stock-900x600/16.jpg"
                        )})`,
                      }}
                    >
                      <div class="col-12 px-4 d-flex justify-content-end">
                        <a
                          href="#"
                          class="btn btn-icon bg-light-o-90 btn-sm h-25px w-25px mr-0"
                        >
                          <img
                            class="h-15px"
                            src="/media/svg/icons/custom/Icon_download.svg"
                          />
                        </a>
                      </div>
                      <div class="col-12 px-4">
                        <p
                          class="w-100 mb-1 text-light text-truncate font-weight-SemiBold"
                          title="Create Contents"
                        >
                          Relationship building
                        </p>
                      </div>
                    </div>
                    <div class="d-flex d-flex align-items-center">
                      <div class="flex-grow-1">
                        <div class="d-flex align-items-center mb-0 p-3">
                          <div class="symbol symbol-30 symbol-circle symbol-light-success mr-2">
                            <span
                              class="symbol-label"
                              style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                  "/media/stock-600x600/img-8.jpg"
                                )})`,
                              }}
                            ></span>
                          </div>
                          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                            <a
                              href="#"
                              class="text-dark font-weight-SemiBold text-hover-primary mb-0 font-size-xs text-capitalize"
                            >
                              AnjuBB
                            </a>
                            <span class="text-dark font-size-xs font-weight-Medium">
                              <span class="opacity-50">9 Jul 2022</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Modal size="md" show={show} onHide={handleClose}>
                <Modal.Header>
                  <h3>Brand Details</h3>
                </Modal.Header>
                <Modal.Body className="p-0 ml-8 mt-4">
                  <div className="form-group row">
                    <label className="col-md-1 col-form-label mt-1">Name</label>
                    <div className="ml-6 col-md-8">
                      <input
                        type="text"
                        placeholder="Enter your Brand Name"
                        className={`form-control form-control-lg form-control-solid`}
                        name="companyName"
                        value={inputCompanyName}
                        onChange={(e) => {
                          setInputCompanyName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer class="d-flex justify-content-center py-4">
                  <button
                    class="btn btn-primary mr-4"
                    onClick={() => submitBrandName()}
                  >
                    {loading ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </Modal.Footer>
              </Modal> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
