/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../campaign/_redux/campaignActions";
import { useCampaignUIContext } from "../../campaign/active-campaign-list/CampaignUIContext";
import { useHistory } from "react-router-dom";
import { parseISO } from "date-fns";
import { format } from "date-fns";

export function MyApplications() {
  const dispatch = useDispatch();
  const CampaignUIContext = useCampaignUIContext();
  const history = useHistory();
  const { brandId, currentState } = useSelector(
    (state) => ({
      brandId: state.auth.user.userId,
      currentState: state.campaign,
    }),
    shallowEqual
  );

  const DateFormatter = (cellContent) => {
    let formattedDate = "";
    if (cellContent) {
      let date = parseISO(cellContent);
      formattedDate = format(date, "dd MMM yyyy").toString();
    }
    return <span>{formattedDate}</span>;
  };

  const { applnTotalCount, applications, listLoading } = currentState;

  const applicationStatus = (data) => {
    switch (data) {
      case "WAITING_FOR_APPROVAL_BY_BRAND":
        return "Waiting";
      case "REJECTED_BY_BRAND":
        return "Rejected";
      case "APPROVED_BY_BRAND":
        return "Approved";
      default:
        return "";
    }
  };

  const contentStatus = (data) => {
    switch (data) {
      case "APPROVED_BY_BRAND":
        return "Approved";
      case "REJECTED_BY_BRAND":
        return "Rejected";
      case "WAITING_FOR_APPROVAL_BY_BRAND":
        return "Approval pending";
      case "DRAFT":
        return "Draft";
      case "RESUBMIT_CONTENT":
        return "Resubmission requested";
      case "SOCIAL_MEDIA_URL_REQUESTED":
        return "URL Requested";
      case "URL_SUBMITTED_BY_CREATOR":
        return "URL Submitted";
      case "URL_APPROVED_BY_BRAND":
        return "URL Approved";
      default:
        return "";
    }
  };

  const paymentStatus = (data) => {
    switch (data) {
      case "SUCCESSS":
        return "Paid";
      case "PROGRESS":
        return "In Progress";
      case "AUTHORIZED":
        return "Authorized";
      case "FAILED":
        return "Fail";
      case "CAPTURED":
        return "Paid";
      default:
        return "";
    }
  };

  const statusSwitchApplicationList = (data) => {
    if (
      data?.applicationStatus === "WAITING_FOR_APPROVAL_BY_BRAND" &&
      data?.contentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-warning label-pill label-inline mr-2">
            <i class="flaticon2-time icon-sm mr-1 text-light"></i> {applicationStatus(data?.applicationStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "REJECTED_BY_BRAND" &&
      data?.contentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-danger label-pill label-inline mr-2">
            <i class=" flaticon2-cross icon-sm mr-1 text-light"></i> {applicationStatus(data?.applicationStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-light"></i> {applicationStatus(data?.applicationStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "DRAFT"
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-light"></i> {applicationStatus(data?.applicationStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "WAITING_FOR_APPROVAL_BY_BRAND" &&
      data?.contentStatus === "DRAFT"
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-warning label-pill label-inline mr-2">
            <i class="flaticon2-crisp-icons-1 icon-sm mr-1 text-light"></i> {applicationStatus(data?.applicationStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "REJECTED_BY_BRAND" &&
      data?.contentStatus === "DRAFT"
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-success label-pill label-inline mr-2">
            <i class="flaticon2-cross icon-sm mr-1 text-light"></i> {applicationStatus(data?.applicationStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "APPROVED_BY_BRAND" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-light"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "REJECTED_BY_BRAND" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-danger label-pill label-inline mr-2">
            <i class=" flaticon2-cross icon-sm mr-1 text-danger"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "WAITING_FOR_APPROVAL_BY_BRAND" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-warning label-pill label-inline mr-2">
            <i class="flaticon2-crisp-icons-1 icon-sm mr-1 text-warning"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "RESUBMIT_CONTENT" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-warning label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-warning"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "SOCIAL_MEDIA_URL_REQUESTED" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-warning label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-warning"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "URL_SUBMITTED_BY_CREATOR" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-info label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-info"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (
      data?.applicationStatus === "APPROVED_BY_BRAND" &&
      data?.contentStatus === "URL_APPROVED_BY_BRAND" &&
      data?.paymentStatus === null
    ) {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-info label-pill label-inline mr-2">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-info"></i> {contentStatus(data?.contentStatus)}
          </span>
        </>
      );
    } else if (data?.paymentStatus === "SUCCESSS") {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-success"></i> {paymentStatus(data?.paymentStatus)}
          </span>
        </>
      );
    } else if (data?.paymentStatus === "PROGRESS") {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-warning label-pill label-inline">
            <i class="flaticon2-zig-zag-line-sign icon-sm mr-1 text-warning"></i> {paymentStatus(data?.paymentStatus)}
          </span>
        </>
      );
    } else if (data?.paymentStatus === "AUTHORIZED") {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
            <i class=" flaticon2-check-mark icon-sm mr-1 text-success"></i> {paymentStatus(data?.paymentStatus)}
          </span>
        </>
      );
    } else if (data?.paymentStatus === "FAILED") {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-danger label-pill label-inline">
            <i class="flaticon2-warning icon-sm mr-1 text-danger"></i> {paymentStatus(data?.paymentStatus)}
          </span>
        </>
      );
    } else if (data?.paymentStatus === "CAPTURED") {
      return (
        <>
          <span class="label label-sm font-weight-Medium label-light-success label-pill label-inline">
            <i class="flaticon2-crisp-icons-1 icon-sm mr-1 text-success"></i> {paymentStatus(data?.paymentStatus)}
          </span>
        </>
      );
    }
  };

  useEffect(() => {
    // server call by queryParams
    dispatch(
      brandId &&
        actions.listCampaignApplications({
          loggedInUserId: brandId,
          page: 1,
          pageSize: 3,
          populate: ["creator", "campaign"],
          sort: "-updatedAt",
          sortField: "id",
          sortOrder: "asc",
          query: { brandId },
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <>
      <div class="card-header border-0 pt-0 d-flex align-items-center">
        <div class="card-title font-weight-bolder my-0">
          <div class="card-label">Applications List</div>
        </div>
        <a
          class="font-weight-Medium text-dark cursor-pointer"
          onClick={() => {
            history.push("/applications/list");
          }}
        >
          More
        </a>
      </div>
      <div class="card-body pt-0 pb-0">
        <div class="table-responsive">
          <table class="table campaign_details table-borderless table-vertical-center">
            {applications?.length > 0 && (
              <thead>
                <tr>
                  <th class="p-0 w-80px"></th>
                  <th class="p-0">Campaign Name</th>
                  <th class="p-0 text-right">Status</th>
                  <th class="p-0 text-right min-w-80px">Action</th>
                </tr>
              </thead>
            )}
            <tbody>
              {applications?.length > 0 &&
                applications.map((data, index) => (
                  <tr key={index}>
                    <td class="py-2 pl-0">
                      <div class="symbol symbol-50 symbol-light mr-2">
                        <span
                          class="symbol-label"
                          style={{
                            backgroundImage: data?.campaign?.productImageUrl
                              ? `url(${process.env.REACT_APP_AWS_S3_BASE_URL +
                                  data?.campaign?.productImageUrl})`
                              : `url(${toAbsoluteUrl(
                                  "/media/dummyImages/no_preview_old.png"
                                )})`,
                          }}
                        ></span>
                      </div>
                    </td>
                    <td class="pl-0 pr-0">
                      {data?.campaign?.campaignName && (
                        <a
                          class="font-weight-bolder text-hover-primary mb-1 font-size-lg text-capitalize cursor-pointer"
                          onClick={() =>
                            data?.contentStatus ===
                            "WAITING_FOR_APPROVAL_BY_BRAND"
                              ? history.push(
                                  `/campaigns/list/${data?.campaignId}/view?tab=engagements`
                                )
                              : history.push(
                                  `/campaigns/list/${data?.campaignId}/view?tab=summary`
                                )
                          }
                        >
                          {data?.campaign?.campaignName}
                        </a>
                      )}
                      <span class="text-muted font-weight-bold d-block text-muted font-weight-bold d-block font-size-sm d-flex align-items-center">
                        {data?.creator && (
                          <div class="symbol symbol-20 symbol-circle  symbol-light mr-2">
                            <span
                              class="symbol-label"
                              style={{
                                backgroundImage: data?.creator?.profilePic
                                  ? `url(${process.env
                                      .REACT_APP_AWS_S3_BASE_URL +
                                      process.env
                                        .REACT_APP_S3_USER_PROFILE_FOLDER +
                                      "/" +
                                      data?.creator?.profilePic})`
                                  : `url(${toAbsoluteUrl(
                                      "/media/stock-600x600/img-17.jpg"
                                    )})`,
                              }}
                            ></span>
                          </div>
                        )}
                        {data?.creator && (
                          <span class="text-dark pr-1 text-dark font-weight-SemiBold">
                            {data?.creator?.firstName + data?.creator?.lastName}
                          </span>
                        )}
                        <span class="pr-2">
                          <span>{DateFormatter(data?.updatedAt)}</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-right px-0">
                      {statusSwitchApplicationList(data)}
                    </td>
                    {/* <td class="text-right px-0">
                      <span class="label label-sm font-weight-Medium label-warning label-pill label-inline mr-2">
                        {applicationStatus(data?.applicationStatus)}
                      </span>
                      <span class="label label-sm font-weight-Medium label-outline-info label-pill label-inline mr-2">
                        {contentStatus(data?.contentStatus)}
                      </span>
                      {data?.paymentStatus && (
                        <span class="label label-sm font-weight-Medium label-light-danger label-pill label-inline">
                          {paymentStatus(data?.paymentStatus)}
                        </span>
                      )}
                    </td> */}
                    <td class="text-right pr-0">
                      <a class="btn btn-icon btn-light btn-sm mr-2">
                        <span
                          class="svg-icon-1x"
                          onClick={() => {
                            let ids = {
                              campaignId: data?.campaignId,
                              creatorId: data?.creatorId,
                            };
                            localStorage.setItem("ids", JSON.stringify(ids));
                            history.push({
                              pathname: `/campaigns/list/${data?.campaignId}/view`,
                              search: `?tab=messages`,
                              state: {
                                isNavigationThroughUrl: true,
                                campaignId: data?.campaignId,
                                toCreatorUserDetails: {
                                  ...data?.creator,
                                  userId: data?.creatorId,
                                  campaignId: data?.campaignId,
                                },
                              },
                            });
                          }}
                        >
                          <svg
                            width="17.37"
                            height="17.37"
                            viewBox="0 0 17.37 17.37"
                          >
                            <path
                              d="M20.37,15.08a1.763,1.763,0,0,1-1.763,1.763H8.027L4.5,20.37V6.263A1.763,1.763,0,0,1,6.263,4.5H18.607A1.763,1.763,0,0,1,20.37,6.263Z"
                              transform="translate(-3.75 -3.75)"
                              fill="rgba(0,0,0,0)"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                            ></path>
                          </svg>
                        </span>
                      </a>
                      {/* <a href="#" class="btn btn-icon btn-light btn-sm">
                        <span class="svg-icon-1x">
                          <i class="ki ki-bold-more-ver"></i>
                        </span>
                      </a> */}
                    </td>
                  </tr>
                ))}
            </tbody>
            {applications?.length === 0 && (
              <div class="w-100 no_app_data row align-items-center pb-5 px-0 mx-0">
                <div class="col-md-12 px-0">
                  <img
                    class="mt-0 mr-0 w-100 gray_scale"
                    src="/media/custom/no_application.svg"
                  />
                </div>
                <div class="col-md-6 d-none">
                  <h6 class="font-weight-bold px-0 w-100 mb-0 mt-6 text-dark-50">
                    No Active campaigns yet
                  </h6>
                  <p class="text-dark-50 mb-0 mt-5">
                    Online Advertising flat illustration of the concept of a
                    woman being promoted using a microphone
                  </p>
                  {/* <div class="w-100 mt-5"><a class="text-primary cursor-pointer">Create new</a></div> */}
                </div>
              </div>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
