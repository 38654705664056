import { createSelector } from "@reduxjs/toolkit";

const userDetails = ({ auth }) => {
  return {
    userId: auth.user.userId,
  };
};
const chatState = ({ chatModule, common }) => ({
  socket: common.socket,
  isApiInprogressFlag: chatModule.isApiInprogressFlag,
  chatInboxArray: chatModule.chatInboxArray,
  chatMessagesArray: chatModule.chatMessagesArray,
  campaignDetails: chatModule.campaignDetails,
  creatorDetails: chatModule.creatorDetails,
  totalMessages: chatModule.totalMessages,
});

const applications = ({ application }) => ({
  application: application.entities,
});

export const ChatModuleSelector = createSelector(
  [userDetails, chatState, applications],
  (user, chatState, applications) => ({
    user,
    ...chatState,
    applications,
  })
);
