/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Rating from "@material-ui/lab/Rating";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { Avatar } from "@material-ui/core";
import * as actions from "../../../../../app/modules/_redux/commonActions";
import { viewModalInfo } from "../../../../../app/modules/_redux/commonActions"
import { body1, body2, body3 } from "../../../../../app/pages/helper/ModalBody";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function UserProfileDropdown() {
  const dispatch = useDispatch();
  const [screen,setScreen] = useState(1);
  const { user, profileToEdit, fetchedRating, isVisibleModalInfo } = useSelector(
    (state) => ({
      user: state.auth.user,
      profileToEdit: state.common.profileToEdit,
      fetchedRating: state.common?.fetchedRating,
      isVisibleModalInfo: state.common.isVisibleModalInfo
    }),
    shallowEqual
  );
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  useEffect(() => {
    dispatch(
      actions.getBrandRating({
        brandUserId: user.userId,
        loggedInUserId: user.userId,
      })
    );
    dispatch(
      actions.getCreatorProfile({
        loggedInUserId: user.userId,
        userId: user.userId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const ratedData =
    fetchedRating &&
    (1 * fetchedRating.ratingCount1 +
      2 * fetchedRating.ratingCount2 +
      3 * fetchedRating.ratingCount3 +
      4 * fetchedRating.ratingCount4 +
      5 * fetchedRating.ratingCount5) /
    fetchedRating.ratedUserCount;

  const firstName = () => {
    if (profileToEdit?.firstName === "") {
      return user?.firstName
    } else {
      return profileToEdit?.firstName;
    }
  };

  const lastName = () => {
    if (profileToEdit?.lastName === "") {
      return null
    } else {
      return profileToEdit?.lastName;
    }
  };

  const getmodalScreen = (screenNumber) => {
    switch (screenNumber) {
      case 1:
        return body1
        case 2 : 
       return body2
        case 3 : 
       return body3
        default : return null ;
    }
  }

  const viewModal = (params) => {
    dispatch(viewModalInfo(params));
  }
 
  useEffect(() => {
    if (isVisibleModalInfo === true) {
      screen <= 3 &&  Swal.fire({
        type: "info",
        html: getmodalScreen(screen),
        // text:body1,
        // content: errors,
        showCloseButton: true,
        // showCancelButton: screen === 1 ? false :true,
        confirmButtonText: screen === 3 ? "Close":"Next",
        confirmButtonColor: "#3699FF",
        // cancelButtonColor: "#EBEBED",
        confirmButtonClass: "btn_primary",
        // cancelButtonClass: "btn_secondary",
        cancelButtonText: "Back",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: true,
        allowOutsideClick: false,
        reversebuttonsText: "Back",
        showDenyButton: screen === 1 ? false :true,
        denyButtonColor: "#EBEBED",
        denyButtonClass: "btn_secondary",
        denyButtonText: "Back",

      }).then((resp)=>{
        resp.isConfirmed && setScreen(screen+1);
        resp.isDenied && setScreen(screen - 1);
        resp.isDismissed && dispatch(viewModalInfo(false));
         screen > 3 && dispatch(viewModalInfo(false)) ;
      })
    }
  }, [isVisibleModalInfo, dispatch,screen])
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {profileToEdit.companyName ? profileToEdit.companyName : ""}
          </span>
          <Avatar
            src={
              process.env.REACT_APP_AWS_S3_BASE_URL +
              process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
              "/" +
              profileToEdit.profilePic
            }
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img
                    src={
                      process.env.REACT_APP_AWS_S3_BASE_URL +
                      process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                      "/" +
                      profileToEdit.profilePic
                    }
                    alt=""
                  />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {profileToEdit.companyName ? profileToEdit.companyName : ""}
                </div>
                {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                  <span className="svg-icon svg-icon-md svg-icon-secondary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Visible.svg"
                      )}
                    />
                  </span>
                </span> */}
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                {/* <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user?.firstName}
                </span> */}
                <img
                  alt="Pic"
                  className="hidden"
                  src={
                    profileToEdit.profilePic
                      ? process.env.REACT_APP_AWS_S3_BASE_URL +
                      process.env.REACT_APP_S3_USER_PROFILE_FOLDER +
                      "/" +
                      profileToEdit.profilePic
                      : toAbsoluteUrl("/media/users/blank.png")
                  }
                />
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                <div class="d-flex flex-column">
                  {profileToEdit.companyName ? profileToEdit.companyName : ""}
                  {fetchedRating ? (
                    <Rating
                      name="read-only"
                      value={parseInt(ratedData)}
                      readOnly
                      size="small"
                    />
                  ) : null}
                </div>
              </div>

              {/* <span className="label label-lg font-weight-bold label-inline">
                <Link to="/edit-profile" className="cursor-pointer">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="group-edit-tooltip">Edit profile</Tooltip>
                    }
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
                      />
                    </span>
                  </OverlayTrigger>
                </Link>
              </span> */}
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-primary" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  <span
                    className="label label-light-danger label-inline font-weight-bold"
                    style={{ marginLeft: 10 }}
                  >
                    update
                  </span>
                </div>
              </div>
            </div>
          </Link>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </Link>
            <button
              // title="View CreatorInvitee"
              className="btn btn-clean btn-lg  pulse pulse-primary  btn-sm mx-3"
              onClick={() => { viewModal(true) ; setScreen(1)}}
            >
              {/* <span className="svg-icon svg-icon-md svg-icon-secondary"> */}
              <i className="fas pr-0 fa-info text-primary" />
              {/* </span> */}
            </button>
            {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
