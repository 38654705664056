export const getPage = (data) => {
  switch (data.idType) {
    case "applicationId":
      return `/applications/list/${data.id}/view`;
    case "applicationIdForViewContents":
      return `/applications/list/contents/${data.id}/view`;
    default:
      return "/";
  }
};
