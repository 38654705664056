import React, { useState } from "react";
import { Col, Card } from "react-bootstrap";
import { PopupModal } from "react-calendly";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emptyCampaignTemplates } from "../../campaign/_redux/campaignActions";
import * as actions from "../../../modules/_redux/commonActions";

export function ChooseCampaign({ setOpenTemplate, setCampaignType }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    status: false,
    url: "",
  });

  return (
    <>
      <div id="showCalendly"></div>

      <PopupModal
        url={showModal?.url}
        rootElement={document.getElementById("showCalendly")}
        text="Click here to schedule!"
        onModalClose={() =>
          setShowModal({
            status: false,
            url: "",
          })
        }
        open={showModal?.status}
        textColor="#ffffff"
        color="#00a2ff"
      />

      <div class="col-auto">
        <Col sm className="px-0">
          <Card
            bg="primary"
            text="white"
            className="card max-w-160px card_list min-w-130px bg_gradient_3 overflow-hidden h-220px max-h-220px card_view d-flex  align-items-end flex-nowrap text-white mb-8"
            onClick={() => {
              //   switchRoute.bind(this, "/photography-v2");
              // setOpenTemplate(true);
              // setCampaignType("PHOTOGRAPHY");
              dispatch(emptyCampaignTemplates({}));
              history.push("/template-view/PHOTOGRAPHY");
              dispatch(actions.setRightSideBar(false));
            }}
            style={{ cursor: "pointer" }}
          >
            <Card.Img
              src="/media/stock-600x600/photography_vector.png"
              alt="Create Contents"
            ></Card.Img>
            <Card.ImgOverlay className="card-img-overlay d-flex align-items-end justify-content-between">
              <div class="mb-0 font-weight-bold text-white font-size-lg">
                Content
                <br />
                Designing
                <p class="card-text text-white d-none  font-size-sm font-weight-normal pt-2">
                  Crafting engaging, tailored content to resonate with target
                  audiences across various platforms, enhancing brand identity
                  and communication
                </p>
              </div>
              <div class="center-con pt-0 d-flex align-items-end flex-center flex-wrap align-content-center">
                <div class="round d-flex flex-center align-content-center">
                  <div class="align-content-center d-flex flex-center">
                    <span class="arrow primera next "></span>
                    <span class="arrow segunda next"></span>
                  </div>
                </div>
              </div>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </div>
      <div class="col-auto">
        <Col sm className="px-0">
          <Card
            bg="primary"
            text="white"
            className="card max-w-160px card_list min-w-130px bg_gradient_2 h-220px max-h-220px card_view overflow-hidden d-flex  align-items-end flex-nowrap text-white mb-8"
            onClick={() => {
              //   switchRoute.bind(this, "/social-post-v2");
              // setOpenTemplate(true);
              // setCampaignType("SOCIAL_POST");
              dispatch(emptyCampaignTemplates({}));
              history.push("/template-view/SOCIAL_POST");
              dispatch(actions.setRightSideBar(false));
            }}
            style={{ cursor: "pointer" }}
          >
            <Card.Img
              src="/media/stock-600x600/Influencer_campaign.png"
              alt="Achieve Reach"
            ></Card.Img>
            <Card.ImgOverlay className="card-img-overlay d-flex align-items-end justify-content-between">
              <div class="mb-0 font-weight-bold text-white font-size-lg">
                Influencer <br />
                Marketing
                <p class="card-text text-white d-none  font-size-sm font-weight-normal pt-2">
                  Collaborating with key influencers to promote your brand,
                  products, or services, leveraging their reach and credibility
                  to connect with a broader audience
                </p>
              </div>
              <div class="center-con pt-0 d-flex align-items-end flex-center flex-wrap align-content-center">
                <div class="round d-flex flex-center align-content-center">
                  <div class="align-content-center d-flex flex-center">
                    <span class="arrow primera next "></span>
                    <span class="arrow segunda next"></span>
                  </div>
                </div>
              </div>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </div>
      <div class="col-auto">
        <Col sm className="px-0">
          <Card
            bg="primary"
            text="white"
            className="card max-w-160px card_list min-w-130px bg_gradient_1 h-220px max-h-220px card_view overflow-hidden d-flex  align-items-end flex-nowrap text-white mb-8"
            onClick={() => {
              //   switchRoute.bind(this, "/relationship-building");
              // setOpenTemplate(true);
              // setCampaignType("RELATION_POST");
              dispatch(emptyCampaignTemplates({}));
              history.push("/template-view/RELATION_POST");
              dispatch(actions.setRightSideBar(false));
            }}
            style={{ cursor: "pointer" }}
          >
            <Card.Img
              src="/media/stock-600x600/Relationship_cover.png"
              alt="Grow Customer-base"
            ></Card.Img>
            <Card.ImgOverlay className="card-img-overlay d-flex align-items-end justify-content-between">
              <div class="mb-0 font-weight-bold text-white font-size-lg">
                {" "}
                Celebrity
                <br />
                Endorsement
                <p class="card-text text-white d-none  font-size-sm font-weight-normal pt-2">
                  Partnering with celebrities to endorse your brand, increasing
                  visibility and credibility by associating with well-known
                  personalities
                </p>
              </div>
              <div class="center-con pt-0 d-flex align-items-end flex-center flex-wrap align-content-center">
                <div class="round d-flex flex-center align-content-center">
                  <div class="align-content-center d-flex flex-center">
                    <span class="arrow primera next "></span>
                    <span class="arrow segunda next"></span>
                  </div>
                </div>
              </div>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </div>
      <div class="col-auto">
        <Col sm className="px-0">
          <Card
            bg="primary"
            text="white"
            className="card max-w-160px card_list min-w-130px bg_gradient_4 overflow-hidden h-220px max-h-220px card_view d-flex  align-items-end flex-nowrap text-white mb-8"
            onClick={() =>
              setShowModal({
                status: true,
                url: process.env.REACT_APP_CALENDLY_BOOST_SALES_URL,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <Card.Img
              src="/media/stock-600x600/video_cover.png"
              alt="Boost Sales"
            ></Card.Img>
            <Card.ImgOverlay className="card-img-overlay d-flex align-items-end justify-content-between">
              <div class="mb-0 font-weight-bold text-white font-size-lg">
                Visibility <br />
                Creation
                <p class="card-text text-white d-none  font-size-sm font-weight-normal pt-2">
                  Strategically positioning your brand to enhance public
                  awareness and recognition through online and offline channels
                </p>
              </div>
              <div class="center-con pt-0 d-flex align-items-end flex-center flex-wrap align-content-center">
                <div class="round d-flex flex-center align-content-center">
                  <div class="align-content-center d-flex flex-center">
                    <span class="arrow primera next "></span>
                    <span class="arrow segunda next"></span>
                  </div>
                </div>
              </div>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </div>
      <div class="col-auto">
        <Col sm className="px-0">
          <Card
            bg="primary"
            text="white"
            className="card max-w-160px card_list min-w-130px bg_gradient_5 overflow-hidden h-220px max-h-220px card_view d-flex  align-items-end flex-nowrap text-white mb-8"
            onClick={() =>
              setShowModal({
                status: true,
                url: process.env.REACT_APP_BUILD_STRATEGY_SALES_URL,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <Card.Img
              src="/media/stock-600x600/custom_campaign.png"
              alt="Social Media Campaign"
            ></Card.Img>
            <Card.ImgOverlay className="card-img-overlay d-flex align-items-end justify-content-between">
              <div class="mb-0 font-weight-bold text-white font-size-lg">
                Out-of-Home
                <br />
                Advertising
                <p class="card-text text-white d-none font-size-sm font-weight-normal pt-2">
                  Using outdoor advertising methods, including billboards,
                  transit ads, and other physical spaces, to reach consumers in
                  high-traffic areas
                </p>
              </div>
              <div class="center-con pt-0 d-flex align-items-end flex-center flex-wrap align-content-center">
                <div class="round d-flex flex-center align-content-center">
                  <div class="align-content-center d-flex flex-center">
                    <span class="arrow primera next "></span>
                    <span class="arrow segunda next"></span>
                  </div>
                </div>
              </div>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </div>
      <div class="col-auto">
        <Col sm className="px-0">
          <Card
            bg="primary"
            text="white"
            className="card max-w-160px card_list min-w-130px bg_gradient_6 overflow-hidden h-220px max-h-220px card_view d-flex  align-items-end flex-nowrap text-white mb-8"
            onClick={() =>
              setShowModal({
                status: true,
                url: process.env.REACT_APP_LOYALTY_BUILDING_SALES_URL,
              })
            }
            style={{ cursor: "pointer" }}
          >
            <Card.Img
              src="/media/stock-600x600/Loyalty_build.png"
              alt="Loyalty Building"
            ></Card.Img>
            <Card.ImgOverlay className="card-img-overlay d-flex align-items-end justify-content-between">
              <div class="mb-0 font-weight-bold text-white font-size-lg">
                Loyalty
                <br />
                Building
                <p class="card-text text-white d-none font-size-sm font-weight-normal pt-2">
                  Implementing strategies to retain and reward customers,
                  fostering long-term relationships and brand loyalty through
                  personalized experiences and incentives
                </p>
              </div>
              <div class="center-con pt-0 d-flex align-items-end flex-center flex-wrap align-content-center">
                <div class="round d-flex flex-center align-content-center">
                  <div class="align-content-center d-flex flex-center">
                    <span class="arrow primera next "></span>
                    <span class="arrow segunda next"></span>
                  </div>
                </div>
              </div>
            </Card.ImgOverlay>
          </Card>
        </Col>
      </div>
    </>
  );
}
